import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignUp() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [phone, setPhone] = React.useState("");

  const handleSubmit = async (event) => {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (
      data.get("fullname") !== "" &&
      data.get("email").match(validRegex) &&
      phone !== "" &&
      data.get("password") !== ""
    ) {
      setLoading(true);

      const q = query(
        collection(db, "website_customers"),
        where("phone", "==", phone)
      );

      await getDocs(q).then((querySnapshot) => {
        setLoading(false);
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        if (newData.length !== 0) {
          alert("Phone already exist");
          setLoading(false);
        } else {
          createUser(data);
        }
      });
    } else {
      alert("Please fill all required field");
    }
  };

  const createUser = async (data) => {
    await addDoc(collection(db, "website_customers"), {
      createdDate: new Date().toString(),
      email: data.get("email"),
      name: data.get("fullname"),
      password: data.get("password"),
      phone: phone,
      role: "customer",
      status: true,
      username: data.get("username"),
      orderItems: [
        {
          productId: "",
          status: "Pending",
          thumb: "",
          productName: "",
          color: "",
          size: "",
          qty: "",
          price: "",
          orderId: "",
        },
      ],
      ratedProducts: [{ productId: "", ratingCount: 4 }],
      address: [
        {
          name: "",
          mobile: "",
          pincode: "",
          locality: "",
          street: "",
          city: "",
          state: "",
          landmark: "",
          alternateMobile: "",
          addressType: "",
        },
      ],
    });
    const q = query(
      collection(db, "website_customers"),
      where("phone", "==", phone),
      where("password", "==", data.get("password"))
    );

    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      if (newData.length === 0) {
        alert("Wrong credentials");
      } else {
        localStorage.setItem("customerdetail", JSON.stringify(newData));
      }
    });
    setLoading(false);
    navigate("/");
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="given-name"
                  name="fullname"
                  required
                  fullWidth
                  id="fullname"
                  label="Full Name"
                  autoFocus
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  type="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  fullWidth
                  id="phone"
                  label="Phone"
                  name="phone"
                  type="number"
                  value={phone}
                  autoComplete="family-name"
                  onChange={(e) =>
                    e.target.value.toString().length <= 10
                      ? setPhone(e.target.value)
                      : null
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  fullWidth
                  id="username"
                  label="User Name"
                  name="username"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12} sx={{ display: "flex", flexDirection: "row" }}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword === true ? "text" : "password"}
                  id="password"
                  autoComplete="new-password"
                />
                <IconButton
                  sx={{ marginLeft: "-3rem" }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword === true ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading === false ? false : true}
            >
              {loading === false ? "Sign Up" : "Please wait..."}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
