import React from "react";
import MainHeader from "../../components/mainHeader/MainHeader";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { RWebShare } from "react-web-share";
import {
  AppBar,
  Avatar,
  Button,
  Dialog,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";
import {
  CheckCircle,
  Close,
  Favorite,
  FavoriteBorderOutlined,
  FiberManualRecord,
  LocationOn,
  Search,
  Share,
  ShoppingCart,
  ThumbDown,
  ThumbUp,
} from "@mui/icons-material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BestSellingProducts from "../home/BestSellingProducts";
import Footer from "../../components/footer/Footer";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { DeviceUUID } from "device-uuid";
import LoginDialog from "../../components/loginDialog/LoginDialog";
import { useNavigate } from "react-router-dom";
import ProductRating from "../../components/rating/ProductRating";
import { BackgroundColor } from "../../components/colors/ColorsScheme";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

// const offer = [
//   {
//     text: "Bank Offer5% Cashback on Flipkart Axis Bank Card",
//   },
//   {
//     text: "Special PriceGet at flat ₹279",
//   },
//   {
//     text: "Extra ₹500 Off on Bikes & Scooters on purchase of ₹30,000 or more",
//   },
//   {
//     text: "Partner OfferSign-up for Flipkart Pay Later & get free Times Prime Benefits worth ₹10,000*",
//   },
//   {
//     text: "Partner OfferPurchase now & get 1 surprise cashback coupon in Future",
//   },
// ];

export default function ProductDetail() {
  const navigate = useNavigate();

  const [gallery] = React.useState([1, 2, 3, 4, 5]);
  const [num, setNum] = React.useState();
  const [productList, setProductList] = React.useState([]);
  const [productData, setProductData] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [mainImage, setMainImage] = React.useState("");
  const [selectedGalleryImage, setSelectedGalleryImage] = React.useState(null);
  const [imageIndex, setImageIndex] = React.useState(0);
  const [selectedColor, setSelectedColor] = React.useState(0);
  const [selectedSize, setSelectedSize] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [openRateDialog, setOpenRateDialog] = React.useState(false);
  const [submit, setSubmit] = React.useState(false);
  const [wishlist, setWishlist] = React.useState([]);
  const [color, setColor] = React.useState("");
  const [size, setSize] = React.useState("");
  const [thumb, setThumb] = React.useState("");
  const [alreadyRated, setAlreadyRated] = React.useState(false);
  const [productDelivered, setProductDelivered] = React.useState(false);
  const [coupons, setCoupons] = React.useState([]);

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  const limitChar = 6;
  const handleChange = (e) => {
    if (e.target.value.toString().length <= limitChar) {
      setNum(e.target.value);
    }
  };

  const handleAddToCart = async () => {
    let customerdetail = localStorage.getItem("customerdetail");

    await addDoc(collection(db, "website_cart"), {
      userId:
        customerdetail === null
          ? new DeviceUUID().get()
          : JSON.parse(localStorage.getItem("customerdetail"))[0].id,
      productId: window.location.pathname.replace("/productdetail/", ""),
      cartItem: [
        {
          qty: 1,
          variants: [
            {
              color: color,
              size: size !== undefined ? size : "",
              thumb: thumb,
            },
          ],
        },
      ],
      couponsAssociated: coupons,
    });
  };

  const handleAddToWishlist = async () => {
    let customerdetail = localStorage.getItem("customerdetail");
    let id = window.location.pathname.replace("/productdetail/", "");

    if (customerdetail !== null) {
      let idd = JSON.parse(localStorage.getItem("customerdetail"))[0].id;
      const q = query(
        collection(db, "website_wishlist"),
        where("userId", "==", idd),
        where("productid", "==", id)
      );

      await getDocs(q).then(async (querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        if (newData.length === 0) {
          await addDoc(collection(db, "website_wishlist"), {
            userId: idd,
            productid: id,
          });
          getWishlist();
        }
      });
    } else {
      setOpen(true);
    }
  };

  React.useEffect(() => {
    getProductData();
    getProducts();
    getWishlist();
  }, []);

  const getWishlist = async () => {
    let customerdetail = localStorage.getItem("customerdetail");
    if (customerdetail !== null) {
      let idd = JSON.parse(localStorage.getItem("customerdetail"))[0].id;

      const q = query(
        collection(db, "website_wishlist"),
        where("userId", "==", idd)
      );

      await getDocs(q).then(async (querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setWishlist(newData);
      });
    }
  };

  const getProducts = async () => {
    let id = window.location.pathname.replace("/productdetail/", "");
    const q = query(
      collection(db, "dashboard_product"),
      where("qaChecked", "==", true),
      where("isRejected", "==", false),
      where("drafted", "==", false)
    );
    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      let list = newData.filter((item) => item.id !== id);

      setProductList(list);
    });
  };

  const getCoupons = async (id) => {
    setLoading(true);
    await getDocs(collection(db, "dashboard_coupons")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      let list = newData.filter(
        (e) =>
          e.applyFor === "productwise" &&
          (e.appliedCat.find((ee) => ee === id) ||
            e.appliedproduct.find(
              (eee) =>
                eee === window.location.pathname.replace("/productdetail/", "")
            ))
      );
      setCoupons(list.flat());
      setLoading(false);
    });
  };

  const getProductData = async () => {
    setLoading(true);
    const snap = await getDoc(
      doc(
        db,
        "dashboard_product",
        window.location.pathname.replace("/productdetail/", "")
      )
    );
    if (snap.exists()) {
      getCoupons(snap.data().category.id);
      let customerdetail = localStorage.getItem("customerdetail");

      if (customerdetail !== null) {
        let aa = snap
          .data()
          ?.orderedBy?.find(
            (e) => e.customerId === JSON.parse(customerdetail)[0].id
          );

        if (aa !== undefined) {
          if (aa.status === "Delivered") {
            setProductDelivered(true);
          } else {
            setProductDelivered(false);
          }
        } else {
          setProductDelivered(false);
        }
      }

      if (customerdetail !== null) {
        let aa = snap
          .data()
          ?.productRating.find(
            (e) => e.customerId === JSON.parse(customerdetail)[0].id
          );

        if (aa !== undefined) {
          if (aa.ratingCount > 0) {
            setAlreadyRated(true);
          } else {
            setAlreadyRated(false);
          }
        } else {
          setAlreadyRated(false);
        }
      }

      setProductData(snap.data());

      setSize(snap.data().attributes?.Size?.value[0]);
      setColor(snap.data().variants[0]?.value);
      setThumb(snap.data().variants[0]?.thumb);
    } else {
      console.log("No such document");
    }
    setLoading(false);
  };

  const handleChangeGalleryImage = (link, i) => {
    setMainImage(link);
    setSelectedGalleryImage(i);
  };

  const handleThumbImage = (index, value, image) => {
    setColor(value);
    setThumb(image);
    setSelectedColor(index);
    setImageIndex(index);
    setMainImage(productData?.variants[index]?.thumb);
    setSelectedGalleryImage(null);
  };

  const handleThumbImage1 = () => {
    setMainImage(productData?.variants[imageIndex]?.thumb);
    setSelectedGalleryImage(null);
  };

  const handleSelectSize = (index, value) => {
    setSize(value);
    setSelectedSize(index);
  };

  const handleBuyNow = (image, name, saleprice, variants, vendorId) => {
    let customerdetail = localStorage.getItem("customerdetail");
    if (customerdetail !== null) {
      navigate("/checkout", {
        state: {
          cart: [
            {
              thumb: image,
              id: window.location.pathname.replace("/productdetail/", ""),
              productName: name,
              productSalePrice: saleprice,
              qty: 1,
              size: size,
              color: color,
              variants: [variants],
              vendorId: vendorId,
            },
          ],

          totalPrice:
            JSON.parse(saleprice) < 500
              ? JSON.parse(saleprice) + 40
              : JSON.parse(saleprice),
        },
      });
    } else {
      setOpen(true);
    }
  };

  // const handleRateProduct = () => {
  //   let customerdetail = localStorage.getItem("customerdetail");

  //   if (customerdetail !== null) {
  //     setOpenRateDialog(true);
  //   } else {
  //     setOpen(true);
  //   }
  // };

  const handleCloserateDialog = () => {
    setOpenRateDialog(false);
    setSubmit(false);
    getProductData();
  };

  const wl = wishlist.map((item) => item.productid);

  return (
    <>
      <MainHeader />
      {loading === true ? (
        <>
          <Box sx={{ width: "100%" }}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ flexGrow: 1, p: 1 }}>
            <Grid container>
              <Grid item xs={12} md={3} sm={12}>
                <Item sx={{ boxShadow: "none" }}>
                  <Box>
                    <IconButton
                      sx={{
                        display: "flex",
                        position: "absolute",
                        zIndex: 1,
                        backgroundColor: "#FFF",
                        boxShadow: "0px 0px 2px grey",
                        m: 1,
                        "&:hover": {
                          backgroundColor: "#FFF",
                        },
                      }}
                      onClick={handleAddToWishlist}
                    >
                      {wl.includes(
                        window.location.pathname.replace("/productdetail/", "")
                      ) ? (
                        <Favorite color="error" />
                      ) : (
                        <FavoriteBorderOutlined color="error" />
                      )}
                    </IconButton>

                    <Avatar
                      src={
                        mainImage === ""
                          ? productData?.variants[imageIndex]?.thumb
                          : mainImage
                      }
                      alt=""
                      variant="rounded"
                      sx={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 1,
                    }}
                  >
                    {productData.status === false ||
                    productData.qaChecked === false ||
                    productData.variants[imageIndex].qty === 0 ? (
                      <Button
                        variant="contained"
                        size="large"
                        sx={{
                          backgroundColor: "#FF9F00",
                          width: "100%",
                          borderRadius: 0,
                          height: 60,
                          fontWeight: "600",
                          fontSize: 14,
                        }}
                        onClick={handleAddToCart}
                      >
                        Out of stock
                      </Button>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          size="large"
                          sx={{
                            backgroundColor: "#FF9F00",
                            width: "49%",
                            borderRadius: 0,
                            height: 60,
                            fontWeight: "600",
                            fontSize: 14,
                          }}
                          onClick={handleAddToCart}
                          startIcon={<ShoppingCart />}
                        >
                          Add to Cart
                        </Button>
                        <Button
                          variant="contained"
                          size="large"
                          sx={{
                            backgroundColor: "#FB641B",
                            width: "49%",
                            borderRadius: 0,
                            height: 60,
                            fontWeight: "600",
                            fontSize: 14,
                          }}
                          onClick={() =>
                            handleBuyNow(
                              mainImage === ""
                                ? productData?.variants[imageIndex]?.thumb
                                : mainImage,
                              productData.productName,
                              productData.productSalePrice,
                              productData?.variants[imageIndex],
                              productData.vendorId
                            )
                          }
                          startIcon={<ShoppingCart />}
                        >
                          Buy Now
                        </Button>
                      </>
                    )}
                  </Box>
                </Item>
              </Grid>
              <Grid item xs={12} md={1} sm={12}>
                <Item sx={{ boxShadow: "none" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {},
                    }}
                  >
                    <Paper
                      elevation={1}
                      sx={{
                        width: 72,
                        m: 1,
                        border: selectedGalleryImage === null ? 1 : 0,
                      }}
                    >
                      <Button onClick={() => handleThumbImage1()}>
                        <Avatar
                          src={productData?.variants[imageIndex]?.thumb}
                          alt=""
                          variant="rounded"
                          sx={{ width: 56, height: 56 }}
                        />
                      </Button>
                    </Paper>

                    {productData?.variants[imageIndex]?.gallery
                      .slice(1)
                      .map((item, index) => (
                        <Paper
                          elevation={1}
                          sx={{
                            width: 72,
                            m: 1,
                            border: selectedGalleryImage === index ? 1 : 0,
                          }}
                          key={index}
                        >
                          <Button
                            onClick={() =>
                              handleChangeGalleryImage(item.link, index)
                            }
                          >
                            <Avatar
                              src={item.link}
                              alt=""
                              variant="rounded"
                              sx={{ width: 56, height: 56 }}
                            />
                          </Button>
                        </Paper>
                      ))}
                  </Box>
                </Item>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                sm={12}
                sx={{ maxHeight: "80vh", overflowY: "auto" }}
              >
                <Item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    textAlign: "justify",
                    boxShadow: "none",
                  }}
                >
                  <Typography>{productData.productBrand}</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#000",
                        fontSize: 18,
                        textAlign: "left",
                        marginRight: 2,
                      }}
                    >
                      {productData.productName}
                    </Typography>

                    <RWebShare
                      data={{
                        text: productData.productName,
                        url: window.location.pathname,
                        title: productData.productName,
                      }}
                    >
                      <IconButton
                        sx={{
                          backgroundColor: "#FFF",
                          boxShadow: "0px 0px 2px grey",
                          "&:hover": {
                            backgroundColor: "#FFF",
                          },
                        }}
                      >
                        <Share color="info" />
                      </IconButton>
                    </RWebShare>
                  </Box>
                  <Typography
                    sx={{ color: "green", fontSize: 15, marginTop: 1 }}
                  >
                    Special price
                  </Typography>
                  <Box
                    sx={{ display: "flex", marginTop: 1, alignItems: "center" }}
                  >
                    <Typography
                      sx={{ color: "#000", fontSize: 30, marginRight: 2 }}
                    >
                      ₹{productData.productSalePrice}
                    </Typography>
                    <Typography
                      sx={{
                        color: "grey",
                        fontSize: 18,
                        marginRight: 2,
                        textDecoration: "line-through",
                      }}
                    >
                      ₹{productData.productBasePrice}
                    </Typography>
                    <Typography sx={{ color: "green", fontSize: 18 }}>
                      {100 -
                        (
                          (parseInt(productData.productSalePrice) /
                            parseInt(productData.productBasePrice)) *
                          100
                        ).toFixed(0)}
                      % off
                    </Typography>
                  </Box>

                  {/* <Box
                    sx={{
                      display: "flex",
                      marginTop: 1,
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#FFF",
                        fontSize: 18,
                        marginRight: 2,
                        paddingLeft: 1,
                        backgroundColor: "green",
                        width: 50,
                        height: "30%",
                        borderRadius: 50,
                      }}
                    >
                      4 ☆
                    </Typography>
                    <Typography
                      sx={{
                        color: "grey",
                        fontSize: 18,
                        marginRight: 2,
                        width: 300,
                      }}
                    >
                      13,615 ratings and 1,036 reviews
                    </Typography>
                    <Avatar
                      src="https://static-assets-web.flixcart.com/fk-p-linchpin-web/fk-cp-zion/img/fa_62673a.png"
                      alt=""
                      variant="square"
                      sx={{ width: 72, height: 20 }}
                    />
                  </Box> */}
                  <Box
                    sx={{
                      display: "flex",
                      marginTop: 3,
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Typography
                      sx={{ marginRight: 5, color: "grey", fontWeight: "600" }}
                    >
                      Color:
                    </Typography>
                    {productData.variants.map((item, index) => (
                      <Paper
                        elevation={1}
                        sx={{
                          width: 72,
                          m: 1,
                          border: selectedColor === index ? 1 : 0,
                        }}
                        key={index}
                      >
                        <BootstrapTooltip title={item.value} placement="top">
                          <Button
                            onClick={() =>
                              handleThumbImage(index, item.value, item.thumb)
                            }
                          >
                            <Avatar
                              src={item.thumb}
                              alt=""
                              variant="rounded"
                              sx={{ width: 56, height: 56 }}
                            />
                          </Button>
                        </BootstrapTooltip>
                      </Paper>
                    ))}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      marginTop: 3,
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Typography
                      sx={{ marginRight: 5, color: "grey", fontWeight: "600" }}
                    >
                      Size:
                    </Typography>

                    {productData.attributes?.Size?.value?.map((item, index) => (
                      <Paper elevation={0} sx={{ width: 72, m: 1 }} key={index}>
                        <Button onClick={() => handleSelectSize(index, item)}>
                          <Avatar
                            sx={{
                              backgroundColor: "#FFF",
                              color: "#000",
                              border: selectedSize === index ? 3 : 1,
                              fontSize: 12,
                            }}
                            variant="square"
                          >
                            {item}
                          </Avatar>
                        </Button>
                      </Paper>
                    ))}
                  </Box>

                  {/* <Box>
                    <Typography variant="h6" sx={{ color: "#000" }}>
                      Available offers
                    </Typography>
                    {offer.map((item, index) => (
                      <Box
                        sx={{
                          display: "flex",
                          marginTop: 1,
                          alignItems: "center",
                        }}
                        key={index}
                      >
                        <LocalOffer
                          color="success"
                          sx={{ width: 18, height: 18 }}
                        />
                        <Typography sx={{ color: "#000" }}>
                          {item.text}
                        </Typography>
                      </Box>
                    ))}
                  </Box> */}

                  <Box sx={{ display: "flex", marginTop: 2 }}>
                    <LocationOn color="info" />
                    <Typography>Delivery to</Typography>
                  </Box>

                  <Box sx={{ display: "flex", marginTop: 2 }}>
                    <TextField
                      id="standard-basic"
                      placeholder="Enter delivery pincode"
                      variant="standard"
                      // inputProps={{ maxLength: 6 }}
                      type="number"
                      onChange={(e) => handleChange(e)}
                      defaultValue={num}
                      value={num}
                    />
                    <Button variant="text">Check</Button>
                  </Box>

                  <Box sx={{ display: "flex", marginTop: 2 }}>
                    <Box>
                      <Typography sx={{ color: "#000" }}>
                        Delivery in 2 Days, Sunday | Free{" "}
                      </Typography>
                      <Typography sx={{ fontSize: 12, color: "#000" }}>
                        if ordered before 4:40 PM
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{ textDecoration: "line-through", marginLeft: 1 }}
                      >
                        ₹40
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      marginTop: 3,
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Typography
                      sx={{ marginRight: 5, color: "grey", fontWeight: "600" }}
                    >
                      Seller:
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography sx={{ color: "Highlight" }}>
                            {productData.vendor[0]?.name}
                          </Typography>
                          <Typography
                            sx={{
                              color: "#FFF",
                              fontSize: 12,
                              marginLeft: 2,
                              paddingLeft: 1,
                              backgroundColor: "Highlight",
                              width: 40,
                              height: "30%",
                              borderRadius: 50,
                            }}
                          >
                            4 ☆
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <FiberManualRecord sx={{ width: 10, height: 5 }} />
                          <Typography>10 Days Return Policy</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ width: "100%", marginTop: 3 }}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography sx={{ color: "#000", fontSize: 25 }}>
                          Product Details
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography sx={{ color: "grey" }}>
                            {productData.productShortDesc}
                          </Typography>
                          <Box sx={{ width: 100 }}>
                            <Typography></Typography>
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Box>

                  <Box sx={{ width: "100%", marginTop: 3 }}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography sx={{ color: "#000", fontSize: 25 }}>
                          Product Description
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {productData.productLongDesc.map((item, index) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection:
                                index % 2 === 0 ? "row" : "row-reverse",
                              alignItems: "center",
                            }}
                            key={index}
                          >
                            <Avatar
                              src={item.descthumb}
                              alt=""
                              variant="square"
                              sx={{ width: 126, height: 126 }}
                            />
                            <Box sx={{ marginLeft: 3, margin: 3 }}>
                              <Typography sx={{ fontSize: 18 }}>
                                {item.title}
                              </Typography>
                              <Typography sx={{ fontSize: 12 }}>
                                {" "}
                                {item.desc}
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </Box>

                  {/* <Grid container sx={{ marginTop: 3 }}>
                    <Grid item xs={12} md={4}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{ color: "#000", textAlign: "left" }}
                          variant="h5"
                        >
                          Ratings & Reviews
                        </Typography>
                        <Typography
                          sx={{
                            color: "#FFF",
                            fontSize: 18,
                            marginRight: 1,
                            paddingLeft: 1,
                            paddingRight: 1,
                            backgroundColor: "green",
                            borderRadius: 50,
                          }}
                        >
                          4☆
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={8}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          textAlign: "left",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <Typography>
                          13,944 ratings and 1,056 reviews
                        </Typography>
                      </Box>
                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          width: "100%",
                          backgroundColor: BackgroundColor,
                          color: TextColorBlack,
                        }}
                        onClick={() => handleRateProduct()}
                      >
                        Rate Product
                      </Button>
                    </Grid>
                  </Grid> */}
                  <Dialog
                    open={openRateDialog}
                    onClose={() => setOpenRateDialog(false)}
                    maxWidth="xl"
                  >
                    <AppBar
                      sx={{
                        position: "relative",
                        backgroundColor: BackgroundColor,
                      }}
                    >
                      <Toolbar
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          edge="start"
                          onClick={() => handleCloserateDialog()}
                          aria-label="close"
                        >
                          <Close />
                        </IconButton>
                        <Button
                          variant="contained"
                          sx={{ backgroundColor: "#FF9F00" }}
                          onClick={() => setSubmit(true)}
                          disabled={
                            submit === true ||
                            alreadyRated === true ||
                            productDelivered === false
                              ? true
                              : false
                          }
                        >
                          Submit
                        </Button>
                      </Toolbar>
                    </AppBar>
                    {productDelivered === true ? (
                      alreadyRated === false ? (
                        <ProductRating
                          id={window.location.pathname.replace(
                            "/productdetail/",
                            ""
                          )}
                          submit={submit}
                        />
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            p: 5,
                          }}
                        >
                          <Typography variant="h5">Already rated</Typography>
                        </Box>
                      )
                    ) : (
                      <Box
                        sx={{ display: "flex", justifyContent: "center", p: 5 }}
                      >
                        <Typography variant="h5">
                          Product not yet purchased
                        </Typography>
                      </Box>
                    )}
                  </Dialog>

                  {/* {gallery.map((item) => (
                    <Grid
                      container
                      sx={{
                        marginTop: 3,
                        boxShadow: "2px 2px 2px #EEF2F1",
                        p: 1,
                      }}
                      key={item}
                    >
                      <Grid item xs={12} md={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 1,
                            justifyContent: "space-between",
                          }}
                        >
                          <Box sx={{ display: "flex" }}>
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: 18,
                                marginRight: 1,
                                paddingLeft: 1,
                                paddingRight: 1,
                                backgroundColor: "green",
                                borderRadius: 50,
                                height: "10%",
                              }}
                            >
                              4☆
                            </Typography>
                            <Typography sx={{ textAlign: "left" }}>
                              This t shirt is very nice
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex" }}>
                            <ThumbUp sx={{ marginRight: 1 }} />
                            <Typography sx={{ marginRight: 1 }}>343</Typography>
                            <ThumbDown sx={{ marginRight: 1 }} />
                            <Typography>34</Typography>
                          </Box>
                        </Box>
                        <Avatar
                          src="https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/w/d/m/s-ts12-vebnor-original-imagppbwtttnamyp.jpeg?q=70"
                          alt=""
                          variant="rounded"
                          sx={{ width: 76, height: 76 }}
                        />
                      </Grid>
                      <Grid item>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>Ravi shankar</Typography>
                          <Typography>3 months ago</Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <CheckCircle
                            sx={{ width: 20, height: 20, marginRight: 1 }}
                            color="success"
                          />
                          <Typography>
                            Certified Buyer, Bahraich District
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item></Grid>
                    </Grid>
                  ))} */}

                  {/* <Grid container sx={{ marginTop: 3 }}>
                    <Grid item xs={12} md={4}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{ color: "#000", textAlign: "left" }}
                          variant="h5"
                        >
                          Questions and Answers
                        </Typography>
                        <Search sx={{ color: "#000" }} />
                      </Box>
                    </Grid>
                  </Grid> */}

                  {/* {gallery.map((item) => (
                    <Grid
                      container
                      sx={{
                        marginTop: 3,
                        boxShadow: "2px 2px 2px #EEF2F1",
                        p: 1,
                      }}
                      key={item}
                    >
                      <Grid item xs={12} md={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",

                            justifyContent: "space-between",
                          }}
                        >
                          <Box sx={{ display: "flex" }}>
                            <Typography
                              sx={{
                                color: "#000",
                                fontSize: 18,
                              }}
                            >
                              Q.
                            </Typography>
                            <Typography
                              sx={{
                                color: "#000",
                                fontSize: 18,
                                textAlign: "left",
                              }}
                            >
                              Is it suitable for summet ?
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item sx={{ marginBottom: 1 }}>
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            sx={{
                              color: "#000",
                              fontSize: 15,
                            }}
                          >
                            A.
                          </Typography>
                          <Typography
                            sx={{
                              color: "#000",
                              fontSize: 15,
                            }}
                          >
                            what do you mean by summet\ if it is summer it is
                            comfortable.
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>Ravi shankar</Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <CheckCircle
                            sx={{ width: 20, height: 20, marginRight: 1 }}
                            color="success"
                          />
                          <Typography>Certified Buyer</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <ThumbUp
                            sx={{ marginRight: 1, width: 16, height: 16 }}
                            color="info"
                          />
                          <Typography sx={{ marginRight: 1, fontSize: 14 }}>
                            343
                          </Typography>
                          <ThumbDown
                            sx={{ marginRight: 1, width: 16, height: 16 }}
                            color="info"
                          />
                          <Typography sx={{ fontSize: 14 }}>34</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  ))} */}
                </Item>
              </Grid>
            </Grid>
          </Box>
          <Grid container>
            <Grid item xs={12}>
              <BestSellingProducts
                title="You might be interested in"
                showBanner={true}
                productlist={productList}
                cols={3}
                place="Best selling"
              />
            </Grid>
            <Grid item xs={12}>
              <BestSellingProducts
                title="Similar Products"
                showBanner={false}
                productlist={productList}
                cols={4}
                place="Best selling"
              />
            </Grid>
            <Grid item xs={12}>
              <BestSellingProducts
                title="Recently Viewed"
                showBanner={false}
                productlist={productList}
                cols={4}
                place="Best selling"
              />
            </Grid>
            <Grid item xs={12}>
              <BestSellingProducts
                title="Bought Together"
                showBanner={false}
                productlist={productList}
                cols={4}
                place="Best selling"
              />
            </Grid>
          </Grid>
          <Box sx={{ marginTop: 7 }}>
            <Footer />
          </Box>
        </>
      )}

      <LoginDialog open={open} setOpen={setOpen} showcancel={true} />
    </>
  );
}
