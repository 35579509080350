import React from "react";
import MainHeader from "../../components/mainHeader/MainHeader";
import Footer from "../../components/footer/Footer";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../../firebase";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import { FacebookOutlined, Instagram, Twitter } from "@mui/icons-material";

export default function AboutUs() {
  const [aboutUsData, setAboutUsData] = React.useState([]);
  const [firstSectionHover, setFirstSectionHover] = React.useState(false);
  const [secondSectionHover, setSecondSectionHover] = React.useState(false);

  React.useEffect(() => {
    getPolicies();
  }, []);

  const getPolicies = async () => {
    const q = query(collection(db, "website_aboutus"));
    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setAboutUsData(newData);
    });
  };

  const handleHover = (e) => {
    setTimeout(() => {
      setFirstSectionHover(e);
    }, 200);
  };
  const handleHover1 = (e) => {
    setTimeout(() => {
      setSecondSectionHover(e);
    }, 200);
  };

  return (
    <>
      <MainHeader />
      <Box sx={{ padding: 0 }}>
        <Typography
          sx={{
            color: "#102D5E",
            fontSize: 40,
            fontWeight: "600",
            textAlign: "center",
            marginBottom: 5,
            marginTop: 5,
          }}
        >
          About Us
        </Typography>
        <Avatar
          alt=""
          src={aboutUsData[0]?.bannerImage}
          variant="square"
          sx={{ width: "100%", height: "auto" }}
        />
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 5,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Paper elevation={3} sx={{ padding: 5 }}>
              <Typography
                sx={{
                  color: "#70819E",
                  fontSize: 22,
                  fontWeight: "600",
                  lineHeight: 1,
                  marginBottom: 2,
                }}
              >
                {aboutUsData[0]?.firstSection[0]?.mainSubtitle}
              </Typography>
              <Typography
                sx={{
                  color: "#102D5E",
                  fontSize: 40,
                  fontWeight: "600",
                  lineHeight: 1,
                  marginBottom: 2,
                }}
              >
                {aboutUsData[0]?.firstSection[0]?.mainTitle}
              </Typography>
              <Typography>
                {aboutUsData[0]?.firstSection[0]?.mainDesc}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} sx={{ paddingTop: 5, paddingBottom: 5 }}>
            <span
              onMouseOver={() => handleHover(true)}
              onMouseOut={() => handleHover(false)}
            >
              {firstSectionHover === true ? (
                <Paper
                  elevation={3}
                  sx={{
                    padding: 5,
                    marginBottom: 5,
                    backgroundColor: "#102D5E",
                  }}
                >
                  <Typography
                    sx={{ color: "#FFF", fontSize: 40, fontWeight: "600" }}
                  >
                    {aboutUsData[0]?.firstSection[2]?.firstSectionHoverTitle}
                  </Typography>
                  <Typography sx={{ color: "#FFF" }}>
                    {aboutUsData[0]?.firstSection[2]?.firstSectionHoverDesc}
                  </Typography>
                  <Button
                    variant="outlined"
                    sx={{ color: "#FFF", borderColor: "#FFF", marginTop: 2 }}
                  >
                    SHOW MORE
                  </Button>
                </Paper>
              ) : (
                <Paper
                  elevation={3}
                  sx={{
                    padding: 5,
                    marginBottom: 5,
                    backgroundColor: "#CDF0E9",
                    paddingTop: 6.8,
                    paddingBottom: 6.8,
                  }}
                >
                  <Typography
                    sx={{ color: "#102D5E", fontSize: 40, fontWeight: "600" }}
                  >
                    {aboutUsData[0]?.firstSection[1]?.firstSectionTitle}
                  </Typography>
                  <Typography>
                    {aboutUsData[0]?.firstSection[1]?.firstSectionDesc}
                  </Typography>
                </Paper>
              )}
            </span>
            <span
              onMouseOver={() => handleHover1(true)}
              onMouseOut={() => handleHover1(false)}
            >
              {secondSectionHover === true ? (
                <Paper
                  elevation={3}
                  sx={{ padding: 5, backgroundColor: "#102D5E" }}
                >
                  <Typography
                    sx={{ color: "#FFF", fontSize: 40, fontWeight: "600" }}
                  >
                    {aboutUsData[0]?.firstSection[4]?.secondSectionHoverTitle}
                  </Typography>
                  <Typography sx={{ color: "#FFF" }}>
                    {aboutUsData[0]?.firstSection[4]?.secondSectionHoverDesc}
                  </Typography>
                  <Button
                    variant="outlined"
                    sx={{ color: "#FFF", borderColor: "#FFF", marginTop: 2 }}
                  >
                    SHOW MORE
                  </Button>
                </Paper>
              ) : (
                <Paper
                  elevation={3}
                  sx={{
                    padding: 5,
                    backgroundColor: "#CDF0E9",
                    paddingTop: 5.2,
                    paddingBottom: 5.2,
                  }}
                >
                  <Typography
                    sx={{ color: "#102D5E", fontSize: 40, fontWeight: "600" }}
                  >
                    {aboutUsData[0]?.firstSection[3]?.secondSectionTitle}
                  </Typography>
                  <Typography>
                    {aboutUsData[0]?.firstSection[3]?.secondSectionDesc}
                  </Typography>
                </Paper>
              )}
            </span>
          </Grid>
        </Grid>

        <Grid container sx={{ backgroundColor: "#2D2D2D", padding: 5 }}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ color: "#FFF", fontSize: 35, fontWeight: "600" }}>
              {aboutUsData[0]?.secondSection[0]?.mainSubtitle}
            </Typography>
            <Typography
              sx={{ color: "#E7ED1A", fontSize: 35, fontWeight: "600" }}
            >
              {aboutUsData[0]?.secondSection[0]?.mainTitle}
            </Typography>
            <Typography sx={{ color: "#FFF", marginTop: 2 }}>
              {aboutUsData[0]?.secondSection[0]?.mainDesc}
            </Typography>

            <Typography sx={{ color: "#FFF", marginTop: 2 }}>
              Love Punam Singh
            </Typography>
            <Divider sx={{ bgcolor: "#FFF", marginTop: 2 }} />
          </Grid>
          <Grid item xs={12} md={6} sx={{ padding: 5 }}>
            <Avatar
              alt=""
              src={aboutUsData[0]?.seconSectionImage}
              variant="square"
              sx={{
                width: "100%",
                height: "auto",
              }}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: "flex", justifyContent: "center", padding: 2 }}
          >
            <Typography sx={{ color: "#000", fontSize: 35, fontWeight: "600" }}>
              Team
            </Typography>
          </Grid>
          {aboutUsData[0]?.teamSection.map((item, index) => (
            <Grid item xs={12} md={2} key={index} sx={{ padding: 5 }}>
              <Avatar
                alt=""
                src={item.imageLink}
                variant="square"
                sx={{ width: "100%", height: "auto" }}
              />
              <Typography
                sx={{ fontWeight: "600", color: "#000", marginTop: 2 }}
              >
                {item.name}
              </Typography>
              <Typography
                sx={{
                  fontWeight: "600",
                  marginTop: 1,
                  fontSize: 10,
                }}
              >
                {item.position}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 2,
                }}
              >
                <Link
                  href={item.facebookLink}
                  underline="none"
                  target="_blank"
                  color="inherit"
                >
                  <FacebookOutlined />
                </Link>
                <Link
                  href={item.twitterLink}
                  underline="none"
                  target="_blank"
                  color="inherit"
                >
                  <Twitter />
                </Link>

                <Link
                  href={item.instaLink}
                  underline="none"
                  target="_blank"
                  color="inherit"
                >
                  <Instagram />
                </Link>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Grid container sx={{ padding: 2 }}>
          {/* <video
            autoPlay
            loop
            muted
            poster="https://assets.codepen.io/6093409/river.jpg"
            style={{ width: 1600, height: 400 }}
          >
            <source src={aboutUsData[0]?.videoLink} type="video/mp4" />
          </video> */}
        </Grid>
      </Box>
      <Footer />
    </>
  );
}
