import React from "react";
import MainHeader from "../../components/mainHeader/MainHeader";
import ImageSlider from "../../components/imageSlider/ImageSlider";
import BestSellingProducts from "./BestSellingProducts";
import Footer from "../../components/footer/Footer";
import CategorySlider from "../../components/caruasalSlider/CategoryCaruasal";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { CardGiftcard, Close } from "@mui/icons-material";

export default function Home() {
  const [productList, setProductList] = React.useState([]);
  const [filteredProduct, setFilteredProduct] = React.useState([]);

  const [loading, setLoading] = React.useState(true);
  const [loading1, setLoading1] = React.useState(false);

  const [open, setOpen] = React.useState(false);

  const [name, setName] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [email, setEmail] = React.useState("");
  React.useEffect(() => {
    getProducts();
    if (
      localStorage.getItem("notShowAgain") !== "true" ||
      localStorage.getItem("notShowAgain") === null
    ) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, []);

  const getProducts = async () => {
    setLoading(true);
    const q = query(
      collection(db, "dashboard_product"),
      where("qaChecked", "==", true),
      where("isRejected", "==", false),
      where("drafted", "==", false)
    );
    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setProductList(newData);
      const removeDuplicatesCompliances = () => {
        return newData.reduce((acc, current) => {
          const existing = acc.find(
            (item) => item.category?.name === current.category?.name
          );
          return existing ? acc : [...acc, current];
        }, []);
      };
      let list = removeDuplicatesCompliances();
      setFilteredProduct(list.flat());
    });

    setLoading(false);
  };

  const handleSubmit = async () => {
    if (email !== "") {
      setLoading1(true);
      await addDoc(collection(db, "website_services_request"), {
        email: email,

        createdDate: new Date().toString(),
      });
      setLoading1(false);
      handleClose();
    } else {
      alert("All field required");
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClose1 = () => {
    setOpen(false);
    localStorage.setItem("notShowAgain", true);
  };

  return (
    <>
      <MainHeader />
      <ImageSlider place="Main slider" />

      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <Grid container>
          <Grid item xs={12} md={6}>
            <Avatar
              src="https://old.fafaplussize.com/wp-content/uploads/2022/12/DSC00198-copy.jpg"
              alt=""
              variant="square"
              sx={{ width: "100%", height: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ p: 2, backgroundColor: "#F0E6DE" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>

            <Typography sx={{ color: "#000", fontWeight: "600", fontSize: 18 }}>
              Plus size party wear
            </Typography>
            <Typography
              sx={{
                color: "#000",
                fontWeight: "600",
                fontSize: 30,
                marginTop: 2,
              }}
            >
              Signup Today!
            </Typography>

            <TextField
              size="small"
              variant="outlined"
              placeholder="Email"
              sx={{ marginBottom: 2 }}
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              sx={{ backgroundColor: "#000" }}
              variant="contained"
              fullWidth
              onClick={handleSubmit}
              disabled={loading1 === true ? true : false}
            >
              {loading1 === true ? "Please wait.." : "Submit"}
            </Button>

            <Typography
              sx={{
                color: "#000",
                fontWeight: "600",
                fontSize: 18,
                marginTop: 2,
              }}
            >
              Join the party
            </Typography>
            <Typography sx={{ fontSize: 12, marginTop: 2 }}>
              Sign up for exclusive updates, new arrivals , access to discounts
              & offers.
            </Typography>
            <span style={{ display: "flex", justifyContent: "end" }}>
              <Button
                sx={{ fontSize: 12, marginTop: 2, color: "#000" }}
                variant="text"
                onClick={handleClose1}
              >
                Do not show again
              </Button>
            </span>
          </Grid>
        </Grid>
      </Dialog>

      {loading === true ? (
        <Box sx={{ width: "100%" }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      ) : (
        <>
          {filteredProduct?.map((item, i) => (
            <BestSellingProducts
              key={i}
              title={item?.category?.name}
              showBanner={false}
              productlist={productList.filter(
                (ite) => ite?.category?.name === item?.category?.name
              )}
              cols={4}
              place="Best selling"
            />
          ))}

          {/* <ImageSlider place="Main slider" /> */}

          {/* <BestSellingProducts
            title="Featured Products"
            showBanner={false}
            productlist={productList}
            cols={4}
            place="Best selling"
          /> */}
        </>
      )}

      {/* <CategorySlider stylee="square" width={80} height={100} />
      <ImageSlider place="Main slider" />

      {loading === true ? (
        <Box sx={{ width: "100%" }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      ) : (
        <BestSellingProducts
          title="Womens Collections"
          showBanner={false}
          productlist={productList}
          cols={4}
          place="Best selling"
        />
      )}

      <CategorySlider stylee="square" width={80} height={100} />

      {loading === true ? (
        <Box sx={{ width: "100%" }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      ) : (
        <BestSellingProducts
          title="Mens Collections"
          showBanner={false}
          productlist={productList}
          cols={4}
          place="Best selling"
        />
      )}

      <ImageSlider place="Main slider" />

      {loading === true ? (
        <Box sx={{ width: "100%" }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      ) : (
        <BestSellingProducts
          title="Kids Collections"
          showBanner={true}
          productlist={productList}
          cols={3}
          place="Best selling"
        />
      )}

      <CategorySlider stylee="square" width={80} height={100} /> */}

      <Footer />
    </>
  );
}
