import React from "react";
import MainHeader from "../../components/mainHeader/MainHeader";
import Footer from "../../components/footer/Footer";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../../firebase";
import { Box, Typography } from "@mui/material";
import parse from "html-react-parser";

export default function RefundAndReturn() {
  const [policyData, setPolicyData] = React.useState([]);

  React.useEffect(() => {
    getPolicies();
  }, []);

  const getPolicies = async () => {
    const q = query(collection(db, "website_policies"));
    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setPolicyData(newData);
    });
  };

  return (
    <>
      <MainHeader />
      <Box sx={{ padding: 10 }}>
        <Typography sx={{ fontWeight: "600", fontSize: 30, marginBottom: 5 }}>
          Refund & Return
        </Typography>
        {/* <Typography>{policyData[0]?.refundReturn}</Typography> */}
        {parse(`${policyData[0]?.refundReturn}`)}
      </Box>
      <Footer />
    </>
  );
}
