import React from "react";
import MainHeader from "../../components/mainHeader/MainHeader";
import Footer from "../../components/footer/Footer";
import ReactToPrint from "react-to-print";
import {
  Avatar,
  Box,
  Grid,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import { Download } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { Invoice } from "../../components/invoice/Invoice";

const steps = ["Order confirmed", "Shipped", "Out for delivery", "Delivered"];

export default function OrderDetail() {
  const location = useLocation();
  const [orderData, setOrderData] = React.useState({});
  const [open1, setOpen1] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setOrderData(location.state.data);
  }, []);

  const handleReturn = async (index) => {
    if (status !== "") {
      setLoading(true);
      const snap = await getDoc(doc(db, "dashboard_orders", orderData.id));
      if (snap.exists()) {
        const list = [...snap.data().orderItems];
        list[index]["itemStatus"] = status;

        await updateDoc(doc(db, "dashboard_orders", orderData.id), {
          orderItems: list,
        });
        alert("Order status changed successfully");
        window.location.reload();
      }
    } else {
      alert("Please select any option");
    }
  };

  const handleCancelReturn = async (index) => {
    setLoading(true);
    const snap = await getDoc(doc(db, "dashboard_orders", orderData.id));
    if (snap.exists()) {
      const list = [...snap.data().orderItems];

      list[index]["itemStatus"] = "Return cancel";

      await updateDoc(doc(db, "dashboard_orders", orderData.id), {
        orderItems: list,
      });
      alert("Order status changed successfully");
      window.location.reload();
    }
  };

  const handleCancelOrder = async (index) => {
    setLoading(true);
    const snap = await getDoc(doc(db, "dashboard_orders", orderData.id));
    if (snap.exists()) {
      const list = [...snap.data().orderItems];

      list[index]["itemStatus"] = "Cancelled";
      list[index]["cancelDate"] = new Date().toString();

      await updateDoc(doc(db, "dashboard_orders", orderData.id), {
        orderItems: list,
      });
      alert("Order status changed successfully");
      window.location.reload();
    }
  };

  // const refundPayment = async () => {
  //   var myHeaders = new Headers();
  //   myHeaders.append('content-type', 'application/json');
  //   myHeaders.append(
  //     'Authorization',
  //     'Basic cnpwX2xpdmVfWmVYV1FxVkNGZDBSU1o6aWF4SEVjRFhVN2xPaFlBMlR4YTJYWHFN',
  //   );

  //   var raw = JSON.stringify({
  //     amount: 100,
  //     reverse_all: 1,
  //   });

  //   var requestOptions = {
  //     method: 'POST',
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: 'follow',
  //   };

  //   fetch(
  //     `https://api.razorpay.com/v1/payments/${paymentData[0]?.transactionId}/refund`,
  //     requestOptions,
  //   )
  //     .then(response => response.text())
  //     .then(result => console.log(result))
  //     .catch(error => console.log('error', error));
  // };

  const componentRef = React.useRef();

  return (
    <>
      <div style={{ display: "none" }}>
        <Invoice orderData={orderData} ref={componentRef} />
      </div>

      <MainHeader />
      {orderData.orderItems?.map((item, index) => (
        <Box sx={{ p: { xs: 1, md: 5 } }} key={index}>
          <Paper sx={{ p: 3 }}>
            <Grid container>
              <Grid item xs={12} md={3} sx={{ marginTop: 2 }}>
                <Typography variant="h6">Delivery Address</Typography>
                <Typography sx={{ fontSize: 13 }}>
                  {orderData.shippingAddress[0]?.name}
                </Typography>
                <Typography sx={{ fontSize: 13 }}>
                  {orderData.shippingAddress[0]?.streetName +
                    ", " +
                    orderData.shippingAddress[0]?.city +
                    ", " +
                    orderData.shippingAddress[0]?.state +
                    ", " +
                    orderData.shippingAddress[0]?.pincode}
                </Typography>
                <Typography sx={{ fontSize: 13 }}>
                  Phone number: {orderData.shippingAddress[0]?.phone}
                </Typography>
                <Typography variant="h6">Payment status</Typography>
                <Typography sx={{ fontSize: 13 }}>
                  Method: {orderData.paymentStatus[0].method}
                </Typography>
                <Typography sx={{ fontSize: 13 }}>
                  Status: {orderData.paymentStatus[0].paymentStatus}
                </Typography>
                <Typography sx={{ fontSize: 13 }}>
                  Total Amount: {orderData.paymentStatus[0].totalAmnt}
                </Typography>
              </Grid>
              <Grid item xs={12} md={1} sx={{ marginTop: 3 }}>
                <Avatar
                  src={item.thumb}
                  alt=""
                  variant="square"
                  sx={{ width: 82, height: 76 }}
                />
              </Grid>
              <Grid item xs={12} md={2} sx={{ marginTop: 2 }}>
                <Typography variant="h6" sx={{ fontSize: 16 }}>
                  {item.productName}
                </Typography>
                <Typography sx={{ fontSize: 13 }}>
                  Color: {item.variants[0]?.variantValue}
                </Typography>
                <Typography sx={{ fontSize: 13 }}>
                  Size: {item.variants[1]?.variantValue}
                </Typography>
                <Typography sx={{ fontSize: 13 }}>Qty: {item.qty}</Typography>

                <Typography sx={{ fontSize: 13 }}>
                  ₹
                  {item.codetype === "percentage"
                    ? (
                        item.salePrice * item.qty -
                        item.salePrice * item.qty * (item.codevalue / 100)
                      ).toFixed(0)
                    : item.salePrice * item.qty - item.codevalue}
                </Typography>

                {item.couponcode !== "" ? (
                  <Typography>Coupon: {item.couponcode}</Typography>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6} sx={{ marginTop: 2 }}>
                {item.itemStatus !== "Cancelled" ? (
                  <Stepper
                    activeStep={
                      item.itemStatus === "Pending"
                        ? 1
                        : item.itemStatus === "Delivered"
                        ? 4
                        : item.itemStatus === "Shipped"
                        ? 2
                        : item.itemStatus === "Cancelled"
                        ? 5
                        : 3
                    }
                  >
                    {steps.map((label) => (
                      <Step
                        key={label}
                        sx={{
                          "& .MuiStepLabel-root .Mui-completed": {
                            color: "green",
                          },
                        }}
                      >
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                ) : (
                  <Stepper activeStep={2} alternativeLabel>
                    <Step
                      sx={{
                        "& .MuiStepLabel-root .Mui-completed": {
                          color: "green",
                        },
                      }}
                    >
                      <StepLabel>Order confirmed</StepLabel>
                    </Step>
                    <Step
                      sx={{
                        "& .MuiStepLabel-root .Mui-completed": {
                          color: "red",
                        },
                      }}
                    >
                      <StepLabel>Order cancelled</StepLabel>
                    </Step>
                  </Stepper>
                )}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    marginTop: 5,
                  }}
                >
                  <ReactToPrint
                    trigger={() => (
                      <Button startIcon={<Download />} variant="contained">
                        Download invoice
                      </Button>
                    )}
                    content={() => componentRef.current}
                  />

                  {item.itemStatus === "Pending" ||
                  item.itemStatus === "Shipped" ||
                  item.itemStatus === "Outfordeliver" ? (
                    <Button
                      variant="contained"
                      sx={{ marginTop: 2 }}
                      onClick={() => handleCancelOrder(index)}
                      disabled={loading === true ? true : false}
                    >
                      {loading === true ? "Please wait..." : "Cancel order"}
                    </Button>
                  ) : null}
                  {item.itemStatus === "Delivered" ? (
                    <>
                      <Button
                        variant="contained"
                        sx={{ marginTop: 2 }}
                        onClick={() => setOpen1(true)}
                        disabled={loading === true ? true : false}
                      >
                        Return / Replace order
                      </Button>
                    </>
                  ) : null}
                  {item.itemStatus === "Return cancel" ? (
                    <>
                      <Button
                        variant="contained"
                        sx={{ marginTop: 2 }}
                        onClick={() => setOpen1(true)}
                        disabled={loading === true ? true : false}
                      >
                        Return / Replace order
                      </Button>
                    </>
                  ) : null}
                  {item.itemStatus === "Return" ? (
                    <>
                      <Button
                        variant="contained"
                        sx={{ marginTop: 2 }}
                        onClick={() => handleCancelReturn(index)}
                        disabled={loading === true ? true : false}
                      >
                        {loading === true ? "Please wait..." : "Cancel return"}
                      </Button>
                    </>
                  ) : null}

                  {open1 === true ? (
                    <Dialog
                      open={open1}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      maxWidth="lg"
                    >
                      <DialogContent>
                        <FormControl fullWidth sx={{ width: 200 }}>
                          <InputLabel id="demo-simple-select-label">
                            Change status
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Change status"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <MenuItem value="Return">return</MenuItem>
                            <MenuItem value="Replace">Replace</MenuItem>
                          </Select>
                        </FormControl>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setOpen1(false)}>Cancel</Button>
                        <Button
                          onClick={() => handleReturn(index)}
                          variant="contained"
                          disabled={loading === true ? true : false}
                        >
                          {loading === true ? "Please wait..." : "Submit"}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  ) : null}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      ))}
      <Footer />
    </>
  );
}
