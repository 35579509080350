import * as React from "react";

import Card from "@mui/material/Card";

import CardActions from "@mui/material/CardActions";

import IconButton from "@mui/material/IconButton";
import { RWebShare } from "react-web-share";
import CloseIcon from "@mui/icons-material/Close";
import ShareIcon from "@mui/icons-material/Share";
import {
  AppBar,
  Avatar,
  Box,
  Dialog,
  Link,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  Favorite,
  FavoriteBorderOutlined,
  Visibility,
} from "@mui/icons-material";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import LoginDialog from "../loginDialog/LoginDialog";
import ProductView from "../productView/ProductView";
import { BackgroundColor, TextColorBlack } from "../colors/ColorsScheme";

export default function ProductCard({ slide, productList }) {
  const [open, setOpen] = React.useState(false);
  const [wishlist, setWishlist] = React.useState([]);
  const [productView, setProductView] = React.useState(false);
  React.useEffect(() => {
    getWishlist();
  }, []);

  const getWishlist = async () => {
    let customerdetail = localStorage.getItem("customerdetail");

    if (customerdetail !== null) {
      let idd = JSON.parse(localStorage.getItem("customerdetail"))[0].id;

      const q = query(
        collection(db, "website_wishlist"),
        where("userId", "==", idd)
      );

      await getDocs(q).then(async (querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setWishlist(newData);
      });
    }
  };

  const handleAddToWishlist = async (id) => {
    let customerdetail = localStorage.getItem("customerdetail");

    if (customerdetail !== null) {
      let idd = JSON.parse(localStorage.getItem("customerdetail"))[0].id;

      const q = query(
        collection(db, "website_wishlist"),
        where("userId", "==", idd),
        where("productid", "==", id)
      );

      await getDocs(q).then(async (querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        if (newData.length === 0) {
          await addDoc(collection(db, "website_wishlist"), {
            userId: idd,
            productid: id,
          });
          getWishlist();
        }
      });
    } else {
      setOpen(true);
    }
  };

  const wl = wishlist.map((item) => item.productid);
  return (
    <>
      <Card sx={{ maxWidth: productList === true ? {xl: 200} : '100%', marginBottom: 1, marginRight: 1, marginTop: 1 }}>
        <Box
          sx={{
            display: "flex",
            marginLeft: 1,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ color: "gray", fontSize: 12 }}>
            {slide.productBrand}
          </Typography>

          {/* <Avatar
            src="https://static-assets-web.flixcart.com/fk-p-linchpin-web/fk-cp-zion/img/fa_62673a.png"
            alt=""
            variant="square"
            sx={{ width: 45, height: 12 }}
          /> */}
        </Box>

        <Link href={`/productdetail/${slide.id}`}>
          <Box>
            <Typography
              sx={{
                backgroundColor: "#309E8C",
                color: "white",
                position: "absolute",
                width: 60,
                fontSize: "12px",
                borderTopRightRadius: 50,
                borderBottomRightRadius: 50,
                zIndex: 1,
              }}
            >
              Trending
            </Typography>

            <Avatar
              src={slide?.variants[0]?.thumb}
              alt=""
              variant="square"
              sx={{
                width: {
                  xs: productList === true ? "50vw" : 200,
                  md: 200,
                  lg: productList === true ? 200 : 200,
                  sm: productList === true ? 200 : 200,
                  xl: productList === true ? 210 : 210,
                },
                height: {
                  xs: productList === true ? 300 : 260,
                  md: 300,
              
                  lg: productList === true ? 250 : 250,
                  xl: productList === true ? 290 : 290,
                },
              }}
            />
            <Typography
              sx={{
                backgroundColor: "#C1E8E1",
                color: "white",
                position: "absolute",
                width: 22,
                marginTop: -3,
                fontSize: "12px",
                borderTopRightRadius: 50,
                borderBottomRightRadius: 50,
              }}
            >
              AD
            </Typography>
          </Box>
        </Link>

        <Link href="#" underline="none">
          <Typography
            sx={{
              color: "black",
              fontWeight: "600",
              display: "flex",
              fontSize: 12,
              marginLeft: 1,
            }}
          >
            {slide.productName.slice(0, 20) +
              (slide.productName.length > 20 ? "..." : "")}
          </Typography>
        </Link>

        <Box sx={{ display: "flex", marginLeft: 1, alignItems: "center" }}>
          <Typography
            sx={{
              color: "black",
              fontWeight: "600",

              fontSize: 15,
            }}
          >
            ₹{slide.productSalePrice}
          </Typography>
          <Typography
            sx={{
              color: "grey",
              fontWeight: "500",
              marginLeft: 1,
              fontSize: { xs: 10, md: 12 },
              textDecoration: "line-through",
            }}
          >
            ₹{slide.productBasePrice}
          </Typography>
          {/* <Typography
            sx={{
              color: "green",
              fontWeight: "500",
              marginLeft: 1,
              fontSize: { xs: 10, md: 12, xl: 10 },
            }}
          >
            {100 -
              (
                (parseInt(slide.productSalePrice) /
                  parseInt(slide.productBasePrice)) *
                100
              ).toFixed(0)}
            % off
          </Typography> */}
          {/* <Typography
            sx={{
              color: "#FFF",
              fontSize: { xs: 10, md: 12 },
              marginLeft: 2,
              paddingLeft: 1,
              backgroundColor: "green",
              width: { xs: 20, md: 30 },
              height: "10%",
              borderRadius: 50,
            }}
          >
            4 ☆
          </Typography> */}
        </Box>

        <CardActions
          disableSpacing
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <IconButton
            aria-label="add to favorites"
            onClick={() => handleAddToWishlist(slide.id)}
          >
            {wl.includes(slide.id) ? (
              <Favorite color="warning" />
            ) : (
              <FavoriteBorderOutlined color="warning" />
            )}
          </IconButton>

          <RWebShare
            data={{
              text: slide.productName,
              url: `/productdetail/${slide.id}`,
              title: slide.productName,
            }}
          >
            <IconButton aria-label="share">
              <ShareIcon color="info" />
            </IconButton>
          </RWebShare>

          <IconButton aria-label="view" onClick={() => setProductView(true)}>
            <Visibility color="success" />
          </IconButton>
        </CardActions>
      </Card>
      <LoginDialog open={open} setOpen={setOpen} showcancel={true} />
      <Dialog
        open={productView}
        onClose={() => setProductView(false)}
        // fullScreen
        maxWidth="xl"
      >
        <AppBar sx={{ position: "relative", backgroundColor: BackgroundColor }}>
          <Toolbar>
            <IconButton
              edge="start"
              color='default'
              onClick={() => setProductView(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <ProductView id={slide.id} />
      </Dialog>
    </>
  );
}
