import React from "react";
import MainHeader from "../../components/mainHeader/MainHeader";
import Footer from "../../components/footer/Footer";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import LoginDialog from "../../components/loginDialog/LoginDialog";

export default function WishList() {
  let customerdetail = localStorage.getItem("customerdetail");
  const [open, setOpen] = React.useState(false);

  const [wishlist, setWishlist] = React.useState([]);

  React.useEffect(() => {
    if (customerdetail === null) {
      setOpen(true);
    }
    getWishlistData();
  }, [customerdetail]);

  const getWishlistData = async () => {
    onSnapshot(
      collection(db, "website_wishlist"),

      async (docc) => {
        const newData = docc.docs.map((doccc) => ({
          ...doccc.data(),
          id: doccc.id,
        }));

        if (customerdetail !== null) {
          const newdata = await Promise.all(
            newData
              .filter(
                (el) =>
                  el.userId ===
                  JSON.parse(localStorage.getItem("customerdetail"))[0].id
              )
              .map(async (el) => {
                const snap = await getDoc(
                  doc(db, "dashboard_product", el.productid)
                );

                return Object.assign({}, snap.data(), {
                  wishlistid: el.id,
                  id: snap.id,
                });
              })
          );

          setWishlist(newdata.flat());
        } else {
        }
      }
    );
  };

  const handleRemove = async (id) => {
    let customerdetail = localStorage.getItem("customerdetail");

    if (customerdetail === null) {
      setOpen(true);
    } else {
      const docRef = doc(db, "website_wishlist", id);

      deleteDoc(docRef)
        .then(() => {
          console.log("");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <MainHeader />
      <LoginDialog open={open} setOpen={setOpen} showcancel={false} />

      {customerdetail !== null ? (
        <Grid container gap={2} sx={{ marginTop: 3, marginBottom: 3, p: 2 }}>
          <Grid item xs={12} md={3}>
            {/* <Paper sx={{ p: 2 }}>
              <Typography>My profile</Typography>
            </Paper> */}
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 2 }}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "600",
                  borderBottom: 1,
                  borderBottomColor: "#DDE2E1",
                }}
              >
                My wishlist ({wishlist.length})
              </Typography>
              {wishlist?.map((item, index) => (
                <Box
                  sx={{
                    marginTop: 3,
                    borderBottom: 1,
                    borderBottomColor: "#DDE2E1",
                    p: 2,
                  }}
                  key={index}
                >
                  <Grid container>
                    <Grid item xs={12} md={2} sx={{ marginRight: 3 }}>
                      <Link href={`/productdetail/${item.id}`}>
                        <Avatar
                          src={
                            item.variants !== undefined
                              ? item.variants[0]?.thumb
                              : null
                          }
                          alt=""
                          variant="square"
                          sx={{ width: 106, height: 136 }}
                        />
                      </Link>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Link
                          href={`/productdetail/${item.id}`}
                          sx={{ color: "blue", textDecoration: "none" }}
                        >
                          <Typography>{item.productName}</Typography>
                        </Link>
                        <IconButton
                          onClick={() => handleRemove(item.wishlistid)}
                        >
                          <Delete color="error" />
                        </IconButton>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            marginRight: 2,
                            backgroundColor: "green",
                            color: "white",
                            paddingLeft: 1,
                            paddingRight: 1,
                            borderRadius: 1,
                          }}
                        >
                          3.7 ★
                        </Typography>
                        <Typography sx={{ marginRight: 2 }}>(3167)</Typography>
                        <Avatar
                          src="https://static-assets-web.flixcart.com/fk-p-linchpin-web/fk-cp-zion/img/fa_62673a.png"
                          alt=""
                          variant="square"
                          sx={{ width: 56, height: 15 }}
                        />
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            marginRight: 2,
                            fontSize: 20,
                            fontWeight: "600",
                          }}
                        >
                          ₹{item.productSalePrice}
                        </Typography>
                        <Typography
                          sx={{
                            marginRight: 2,
                            textDecoration: "line-through",
                          }}
                        >
                          ₹{item.productBasePrice}
                        </Typography>
                        <Typography sx={{ color: "green" }}>
                          {100 -
                            (
                              (parseInt(item.productSalePrice) /
                                parseInt(item.productBasePrice)) *
                              100
                            ).toFixed(0)}
                          % off
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ))}
              {wishlist.length === 0 ? (
                <Grid container sx={{ marginTop: 20, marginBottom: 20 }}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 3,
                    }}
                  >
                    <Typography variant="h5">
                      No any item in wishlist
                    </Typography>
                  </Grid>
                  {/* <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button variant="contained">Continue shopping</Button>
                  </Grid> */}
                </Grid>
              ) : null}
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", m: 20 }}>
          <Typography variant="h5">Login first to continue</Typography>
        </Box>
      )}
      <Footer />
    </>
  );
}
