import React from "react";
import MainHeader from "../../components/mainHeader/MainHeader";
import Footer from "../../components/footer/Footer";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function Profile() {
  let customerdetail = localStorage.getItem("customerdetail");
  const [name, setName] = React.useState("");
  const [gender, setGender] = React.useState("male");
  const [email, setEmail] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [edit, setEdit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getCustomerDetail();
  }, []);

  const getCustomerDetail = async () => {
    const snap = await getDoc(
      doc(db, "website_customers", JSON.parse(customerdetail)[0].id)
    );

    setName(snap.data().name);
    setEmail(snap.data().email);
    setMobile(snap.data().phone);
  };

  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    await updateDoc(
      doc(db, "website_customers", JSON.parse(customerdetail)[0].id),
      {
        name: name,
        email: email,
        phone: mobile,
        gender: gender,
        editedDate: new Date().toString(),
      }
    );
    setLoading(false);
    window.location.reload();
  };

  return (
    <>
      <MainHeader />
      <Grid
        container
        spacing={2}
        sx={{ marginTop: 3, marginBottom: 3, paddingLeft: 3, paddingRight: 3 }}
      >
        <Grid item xs={12} md={3}>
          <Paper sx={{ p: 2 }}>
            <Avatar
              src="https://static-assets-web.flixcart.com/fk-p-linchpin-web/fk-cp-zion/img/profile-pic-male_4811a1.svg"
              alt=""
              variant="rounded"
              sx={{ width: 106, height: 106, marginTop: 3 }}
            />
            <Typography sx={{ marginTop: 2 }}>Hello {name}</Typography>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Link
                href="/orders"
                sx={{ marginTop: 3, textDecoration: "none" }}
              >
                My orders
              </Link>
              <Link
                href="/address"
                sx={{ marginTop: 2, textDecoration: "none" }}
              >
                My address
              </Link>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <Paper sx={{ p: 2 }}>
            {edit === false ? (
              <Button variant="text" onClick={() => setEdit(true)}>
                Edit
              </Button>
            ) : null}
            {edit === true ? (
              <>
                <Button
                  variant="contained"
                  onClick={() => handleSubmit()}
                  sx={{ marginRight: 3 }}
                  disabled={loading === true ? true : false}
                >
                  {loading === false ? "Submit" : "Please wait..."}
                </Button>
                <Button variant="outlined" onClick={() => setEdit(false)}>
                  Cancel
                </Button>
              </>
            ) : null}

            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              sx={{ marginTop: 3 }}
              InputProps={{
                readOnly: edit === true ? false : true,
              }}
            />
            <FormControl sx={{ marginTop: 3 }}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Gender
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={handleChangeGender}
                value={gender}
              >
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Male"
                />
              </RadioGroup>
            </FormControl>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ marginTop: 3 }}
              InputProps={{
                readOnly: edit === true ? false : true,
              }}
            />
            <TextField
              label="Mobile"
              variant="outlined"
              fullWidth
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              sx={{ marginTop: 3 }}
              InputProps={{
                readOnly: edit === true ? false : true,
              }}
            />
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}
