import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ProductSlider from "../../components/caruasalSlider/CaruasalSlider";
import { Avatar, Button, Link, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import {
  BackgroundColor,
  TextColorBlack,
} from "../../components/colors/ColorsScheme";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function BestSellingProducts({
  title,
  showBanner,
  productlist,
  cols,
  place,
}) {
  const [banners, setBanners] = React.useState([]);

  React.useEffect(() => {
    getBanners();
  }, []);

  const getBanners = async () => {
    await getDocs(collection(db, "website_banners")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setBanners(newData.filter((e) => e.place === place));
    });
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        marginTop: "2%",

        boxShadow: "5px 5px 30px 10px #E7E7E7",
      }}
    >
      <Grid container>
        <Grid item xs={12} md={2}>
          <Item
            sx={{
              height: "81%",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              justifyContent: "center",
              boxShadow: "none",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "black",
                marginBottom: 3,
                marginTop: 2,
                fontSize: 20,
              }}
            >
              {title}
            </Typography>
            <Link href={`/productlist/AHIhRnCU0Z1UobqbMJxs`}>
              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: BackgroundColor,
                  color: TextColorBlack,
                }}
              >
                View All
              </Button>
            </Link>
          </Item>
        </Grid>
        <Grid item xs={12} md={showBanner === false ? 10 : 8}>
          <Item sx={{ height: "81%", boxShadow: "none" }}>
            <ProductSlider productlist={productlist} cols={cols} />
          </Item>
        </Grid>
        {showBanner === true ? (
          <Grid item xs={12} md={2}>
            <Item sx={{ height: "93%" }}>
              <Card sx={{ maxWidth: "100%", maxHeight: "100%" }}>
                {banners.length > 0 ? (
                  <Avatar
                    src={banners[0]?.image}
                    alt=""
                    variant="square"
                    sx={{ width: "100%", height: 460 }}
                  />
                ) : null}
              </Card>
            </Item>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
}
