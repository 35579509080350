import React from "react";
import MainHeader from "../../components/mainHeader/MainHeader";
import Footer from "../../components/footer/Footer";
import {
  Grid,
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Chip,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Add, Edit } from "@mui/icons-material";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";

export default function Address() {
  let customerdetail = localStorage.getItem("customerdetail");
  const [loading, setLoading] = React.useState(false);
  const [addressList, setAddressList] = React.useState([]);
  const [addressType, setAddressType] = React.useState("");
  const [addNewAddress, setAddNewAddress] = React.useState(false);
  const [name, setName] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [pincode, setPincode] = React.useState("");
  const [locality, setLocality] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [landmark, setLandmark] = React.useState("");
  const [alternateMobile, setAlternateMobile] = React.useState("");

  const handleChangeAddressType = (event) => {
    setAddressType(event.target.value);
  };

  React.useEffect(() => {
    getCustomerData();
  }, []);

  const getCustomerData = async () => {
    const snap = await getDoc(
      doc(db, "website_customers", JSON.parse(customerdetail)[0].id)
    );

    if (snap.exists()) {
      setAddressList(snap.data().address);
    }
  };
  const handleSubmit = async () => {
    let a = [
      {
        name: name,
        mobile: mobile,
        pincode: pincode,
        locality: locality,
        street: street,
        city: city,
        state: state,
        landmark: landmark,
        alternateMobile: alternateMobile,
        addressType: addressType,
      },
    ];
    let arr = addressList.concat(a);
    if (
      name !== "" &&
      mobile !== "" &&
      pincode !== "" &&
      locality !== "" &&
      street !== "" &&
      city !== "" &&
      state !== "" &&
      addressType !== ""
    ) {
      setLoading(true);
      await updateDoc(
        doc(db, "website_customers", JSON.parse(customerdetail)[0].id),
        {
          address: arr,
        }
      );
      setLoading(false);
      window.location.reload();
    } else {
      alert("Required fields can not be empty");
      setLoading(false);
    }
  };
  const handleEditAddress = (item) => {
    setAddNewAddress(true);
    setName(item.name);
    setMobile(item.mobile);
    setPincode(item.pincode);
    setLocality(item.locality);
    setStreet(item.street);
    setCity(item.city);
    setState(item.state);
    setLandmark(item.landmark);
    setAlternateMobile(item.alternateMobile);
    setAddressType(item.addressType);
  };

  const handleAddNewAddress = () => {
    setAddNewAddress(!addNewAddress);
    setName("");
    setMobile("");
    setPincode("");
    setLocality("");
    setStreet("");
    setCity("");
    setState("");
    setLandmark("");
    setAlternateMobile("");
    setAddressType("");
  };
  return (
    <>
      <MainHeader />
      <Typography variant="h5" sx={{ p: 3 }}>
        My address
      </Typography>
      <Grid container>
        <Grid item xs={12} md={4} sx={{ p: 3, marginTop: 1 }}>
          <Paper sx={{ p: 3 }}>
            <Button
              variant="text"
              sx={{ textTransform: "capitalize", p: 2 }}
              startIcon={<Add />}
              onClick={() => handleAddNewAddress()}
            >
              Add new address
            </Button>
            {addNewAddress === true ? (
              <>
                <TextField
                  label="Name*"
                  fullWidth
                  sx={{ marginTop: 2 }}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
                <TextField
                  label="10-digit mobile number*"
                  fullWidth
                  type="number"
                  sx={{ marginTop: 2 }}
                  onChange={(e) =>
                    e.target.value.toString().length <= 10
                      ? setMobile(e.target.value)
                      : null
                  }
                  value={mobile}
                />
                <TextField
                  label="Pincode*"
                  fullWidth
                  type="number"
                  sx={{ marginTop: 2 }}
                  onChange={(e) =>
                    e.target.value.toString().length <= 6
                      ? setPincode(e.target.value)
                      : null
                  }
                  value={pincode}
                />
                <TextField
                  label="Locality*"
                  fullWidth
                  sx={{ marginTop: 2 }}
                  onChange={(e) => setLocality(e.target.value)}
                  value={locality}
                />

                <TextField
                  label="Address (Area & Street)*"
                  fullWidth
                  sx={{ marginTop: 2 }}
                  onChange={(e) => setStreet(e.target.value)}
                  value={street}
                />
                <TextField
                  label="City*"
                  fullWidth
                  sx={{ marginTop: 2 }}
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                />
                <TextField
                  label="State*"
                  fullWidth
                  sx={{ marginTop: 2 }}
                  onChange={(e) => setState(e.target.value)}
                  value={state}
                />
                <TextField
                  label="Landmark"
                  fullWidth
                  sx={{ marginTop: 2 }}
                  onChange={(e) => setLandmark(e.target.value)}
                  value={landmark}
                />
                <TextField
                  label="Alternate mobile number"
                  fullWidth
                  sx={{ marginTop: 2 }}
                  onChange={(e) => setAlternateMobile(e.target.value)}
                  value={alternateMobile}
                />
                <FormControl sx={{ marginTop: 2 }}>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Address type*
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={handleChangeAddressType}
                    value={addressType}
                  >
                    <FormControlLabel
                      value="home"
                      control={<Radio />}
                      label="Home"
                    />
                    <FormControlLabel
                      value="work"
                      control={<Radio />}
                      label="Work"
                    />
                  </RadioGroup>
                </FormControl>
                <Box sx={{ marginTop: 2 }}>
                  <Button
                    variant="contained"
                    sx={{ marginRight: 2 }}
                    onClick={handleSubmit}
                    disabled={loading === true ? true : false}
                  >
                    {loading === true ? "Please wait..." : "Save"}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => setAddNewAddress(!addNewAddress)}
                  >
                    Cancel
                  </Button>
                </Box>
              </>
            ) : null}
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} sx={{ p: 3, maxHeight: "70vh", overflowY: "auto"  }}>
          {addressList.slice(1).map((item, index) => (
            <Paper
              sx={{ p: 3, marginTop: 1, }}
              key={index}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Chip label={item.addressType} />
                <Button
                  variant="contained"
                  sx={{ textTransform: "capitalize" }}
                  startIcon={<Edit />}
                  size="small"
                  onClick={() => handleEditAddress(item)}
                >
                  Edit
                </Button>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
                <Typography sx={{ marginRight: 3, fontSize: 17 }} variant="h6">
                  {item.name}
                </Typography>
                <Typography sx={{ fontSize: 17 }} variant="h6">
                  {item.mobile}
                </Typography>
              </Box>
              <Typography sx={{ fontSize: 15 }}>
                {item.locality +
                  ", " +
                  item.street +
                  ", " +
                  item.city +
                  ", " +
                  item.state}
              </Typography>
              <Typography sx={{ fontSize: 15 }} variant="h6">
                {item.pincode}
              </Typography>
            </Paper>
          ))}
        </Grid>
      </Grid>

      <Footer />
    </>
  );
}
