import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./screens/home/Home";
import ProductDetail from "./screens/productDetail/ProductDetail";
import ProductList from "./screens/productList/ProductList";
import LoginDialog from "./components/loginDialog/LoginDialog";
import WishList from "./screens/wishlist/WishList";
import Cart from "./screens/cart/Cart";
import SignUp from "./screens/signup/SignUp";
import Checkout from "./screens/checkout/Checkout";
import Profile from "./screens/profile/Profile";
import Orders from "./screens/orders/Orders";
import Address from "./screens/address/Address";
import OrderDetail from "./screens/orders/OrderDetail";
import PrivacyPolicy from "./screens/policies/PrivacyPolicy";
import TermsAndCondition from "./screens/policies/TermsAndCondition";
import ShippingAndDelivery from "./screens/policies/ShippingAndDelivery";
import RefundAndReturn from "./screens/policies/RefundAndReturn";
import ExchangePolicy from "./screens/policies/ExchangePolicy";
import AboutUs from "./screens/aboutUs/AboutUs";
import Services from "./screens/services/Services";
import BlogCategory from "./screens/blogs/BlogCategory";
import BlogDetail from "./screens/blogs/BlogDetail";
import BlogCategoryList from "./screens/blogs/BlogCategoryList";
// import Invoice from "./components/invoice/Invoice";

function App() {
  const [open, setOpen] = React.useState(true);

  const PrivateRoute = ({ children }) => {
    let customerdetail = localStorage.getItem("customerdetail");

    return customerdetail !== null ? (
      children
    ) : (
      <LoginDialog open={open} setOpen={setOpen} showcancel={false} />
    );
  };

  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/productdetail/*" element={<ProductDetail />} />
        <Route path="/productlist/*" element={<ProductList />} />
        <Route path="/wishlist" element={<WishList />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termasandcondition" element={<TermsAndCondition />} />
        <Route path="/shippinganddelivery" element={<ShippingAndDelivery />} />
        <Route path="/refundandreturn" element={<RefundAndReturn />} />
        <Route path="/exchangepolicy" element={<ExchangePolicy />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/blogcategory" element={<BlogCategory />} />
        <Route path="/blogdetail" element={<BlogDetail />} />
        <Route path="/blogcategorylist" element={<BlogCategoryList />} />
        <Route
          path="/checkout"
          element={<PrivateRoute children={<Checkout />} />}
        />
        <Route
          path="/profile"
          element={<PrivateRoute children={<Profile />} />}
        />
        <Route
          path="/orders"
          element={<PrivateRoute children={<Orders />} />}
        />{" "}
        <Route
          path="/address"
          element={<PrivateRoute children={<Address />} />}
        />
        <Route
          path="/orderdetail"
          element={<PrivateRoute children={<OrderDetail />} />}
        />
        {/* <Route path="/invoice" element={<Invoice />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
