import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Avatar, Button, Link, Typography } from "@mui/material";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import {
  CardGiftcard,
  Help,
  StarBorderOutlined,
  StoreTwoTone,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Footer() {
  const navigate = useNavigate();
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2, backgroundColor: "#172337" }}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={2}>
            <Item sx={{ backgroundColor: "#172337", boxShadow: "none" }}>
              <Typography
                sx={{
                  color: "grey",
                  fontSize: 10,
                  display: "flex",
                  marginLeft: 2,
                }}
              >
                ABOUT US
              </Typography>

              <MenuList dense>
                <MenuItem onClick={() => navigate("/aboutus")}>
                  <Typography sx={{ color: "white", fontSize: 11 }}>
                    About Us
                  </Typography>
                </MenuItem>

                <MenuItem>
                  <Typography
                    sx={{ color: "white", fontSize: 11 }}
                    onClick={() => navigate("/blogcategorylist")}
                  >
                    Blogs
                  </Typography>
                </MenuItem>
              </MenuList>
            </Item>
          </Grid>
          <Grid item xs={6} md={3}>
            <Item sx={{ backgroundColor: "#172337", boxShadow: "none" }}>
              <Typography
                sx={{
                  color: "grey",
                  fontSize: 10,
                  display: "flex",
                  marginLeft: 2,
                }}
              >
                POLICIES
              </Typography>
              <MenuList dense>
                <MenuItem onClick={() => navigate("/privacypolicy")}>
                  <Typography sx={{ color: "white", fontSize: 11 }}>
                    Privacy Policy
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <Typography
                    sx={{ color: "white", fontSize: 11 }}
                    onClick={() => navigate("/termasandcondition")}
                  >
                    Terms & Conditions
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <Typography
                    sx={{ color: "white", fontSize: 11 }}
                    onClick={() => navigate("/shippinganddelivery")}
                  >
                    Shipping & Delivery
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <Typography
                    sx={{ color: "white", fontSize: 11 }}
                    onClick={() => navigate("/refundandreturn")}
                  >
                    Refund & Returns
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <Typography
                    sx={{ color: "white", fontSize: 11 }}
                    onClick={() => navigate("/exchangepolicy")}
                  >
                    Exchange Policy
                  </Typography>
                </MenuItem>
              </MenuList>
            </Item>
          </Grid>
          {/* <Grid item xs={6} md={2}>
            <Item sx={{ backgroundColor: "#172337", boxShadow: "none" }}>
              <Typography
                sx={{
                  color: "grey",
                  fontSize: 10,
                  display: "flex",
                  marginLeft: 2,
                }}
              >
                CONSUMER POLICY
              </Typography>
              <MenuList dense>
                <MenuItem>
                  <Typography sx={{ color: "white", fontSize: 11 }}>
                    Cancellation & Return
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <Typography sx={{ color: "white", fontSize: 11 }}>
                    Terms Of Use
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <Typography sx={{ color: "white", fontSize: 11 }}>
                    Security
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <Typography sx={{ color: "white", fontSize: 11 }}>
                    Privacy
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <Typography sx={{ color: "white", fontSize: 11 }}>
                    Sitemap
                  </Typography>
                </MenuItem>
              </MenuList>
            </Item>
          </Grid> */}
          <Grid item xs={6} md={3}>
            <Item sx={{ backgroundColor: "#172337", boxShadow: "none" }}>
              <Typography
                sx={{
                  color: "grey",
                  fontSize: 10,
                  display: "flex",
                  marginLeft: 2,
                }}
              >
                SOCIAL
              </Typography>
              <MenuList dense>
                <MenuItem>
                  <Link
                    href="https://www.facebook.com/plussizefafa/"
                    target="_blank"
                  >
                    <Typography sx={{ color: "white", fontSize: 11 }}>
                      Facebook
                    </Typography>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    href="https://twitter.com/fafaplussize?s=11&t=7wqhy8sXSp1JyMIC-qN71Q"
                    target="_blank"
                  >
                    <Typography sx={{ color: "white", fontSize: 11 }}>
                      Twitter
                    </Typography>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    href="https://youtube.com/@fafaplussize7175"
                    target="_blank"
                  >
                    <Typography sx={{ color: "white", fontSize: 11 }}>
                      Youtube
                    </Typography>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    href="https://instagram.com/fafaplussize?igshid=NDk5N2NlZjQ="
                    target="_blank"
                  >
                    <Typography sx={{ color: "white", fontSize: 11 }}>
                      Instagram
                    </Typography>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    href="https://www.linkedin.com/company/fat-and-fab/"
                    target="_blank"
                  >
                    <Typography sx={{ color: "white", fontSize: 11 }}>
                      Linkedin
                    </Typography>
                  </Link>
                </MenuItem>
              </MenuList>
            </Item>
          </Grid>
          <Grid item xs={12} md={2}>
            <Item sx={{ backgroundColor: "#172337", boxShadow: "none" }}>
              <Typography sx={{ color: "grey", fontSize: 10 }}>
                MAIL US
              </Typography>
              <Typography sx={{ color: "white", fontSize: 11 }}>
                hello@fafaplussize.com <br></br>+91 8130582284
              </Typography>
            </Item>
          </Grid>
          {/* <Grid item xs={12} md={2}>
            <Item sx={{ backgroundColor: "#172337", boxShadow: "none" }}>
              <Typography sx={{ color: "grey", fontSize: 10 }}>
                REGISTERED OFFICE ADDRESS:
              </Typography>
              <Typography sx={{ color: "white", fontSize: 11 }}>
                FAFA Plussize LLP, B141, Regal Gardens, Sector-90, Gurugram,
                HR-122505
              </Typography>
            </Item>
          </Grid> */}
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1, marginTop: 2, backgroundColor: "#172356" }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            md={6}
            sx={{ alignItems: "center", display: "flex" }}
          >
            <Item sx={{ backgroundColor: "#172356", boxShadow: "none" }}>
              <Typography sx={{ color: "white", fontSize: 15 }}>
                © 2007-2023 fafaplussize.com
              </Typography>
            </Item>
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Item sx={{ backgroundColor: "#172356", boxShadow: "none" }}>
              <Avatar
                src="https://static-assets-web.flixcart.com/fk-p-linchpin-web/fk-cp-zion/img/payment-method_69e7ec.svg"
                alt=""
                variant="square"
                sx={{ width: "100%", height: 10 }}
              />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
