import React from "react";
import MainHeader from "../../components/mainHeader/MainHeader";
import Footer from "../../components/footer/Footer";
import { addDoc, collection, getDocs, query } from "firebase/firestore";
import { db } from "../../firebase";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";

import { CardGiftcard, Close } from "@mui/icons-material";

export default function Services() {
  const [services, setServices] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [name, setName] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = (item) => {
    setOpen(true);
    setFormData(item);
  };

  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    getServices();
  }, []);

  const getServices = async () => {
    const q = query(collection(db, "website_services"));
    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setServices(newData);
    });
  };

  const handleSubmit = async () => {
    if (name !== "" && mobile !== "" && email !== "") {
      setLoading(true);
      await addDoc(collection(db, "website_services_request"), {
        name: name,
        mobile: mobile,
        email: email,
        serviceName: formData.serviceHeading,
        createdDate: new Date().toString(),
      });
      setLoading(false);
      handleClose();
    } else {
      alert("All field required");
    }
  };

  return (
    <>
      <MainHeader />
      <Box sx={{ padding: 1 }}>
        <Dialog open={open} onClose={handleClose} maxWidth="md">
          <Grid container>
            <Grid item xs={12} md={8}>
              <Avatar
                src={formData.featuredImage}
                alt=""
                variant="square"
                sx={{ width: "100%", height: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={4} sx={{ p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
              </Box>

              <Typography sx={{ color: "#007FFF" }}>
                Get Exclusive Offers and Discounts
              </Typography>
              <Typography
                sx={{ color: "#000", fontWeight: "600", fontSize: 18 }}
              >
                {formData.serviceHeading}
              </Typography>
              <Typography>Book a free consultation today!</Typography>
              <TextField
                size="small"
                variant="outlined"
                placeholder="Full Name"
                sx={{ marginBottom: 2, marginTop: 2 }}
                fullWidth
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                size="small"
                variant="outlined"
                placeholder="Mobile Number"
                sx={{ marginBottom: 2 }}
                fullWidth
                onChange={(e) => setMobile(e.target.value)}
              />
              <TextField
                size="small"
                variant="outlined"
                placeholder="Email"
                sx={{ marginBottom: 2 }}
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                startIcon={<CardGiftcard />}
                variant="contained"
                fullWidth
                onClick={handleSubmit}
                disabled={loading === true ? true : false}
              >
                {loading === true ? "Please wait.." : "Book Now"}
              </Button>
            </Grid>
          </Grid>
        </Dialog>
        <Typography
          sx={{
            color: "#102D5E",
            fontSize: 40,
            fontWeight: "600",
            textAlign: "center",
            marginTop: 5,
          }}
        >
          Services
        </Typography>
        {services.map((item, index) => (
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: index % 2 === 0 ? "row" : "row-reverse",
              alignItems: "center",
              marginBottom: 10,
              padding: 5,
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: 1,
              }}
            >
              <Typography
                sx={{ fontWeight: "600", color: "#000", fontSize: 30 }}
              >
                {item.serviceHeading}
              </Typography>
              <Typography>{item.serviceShortDesc}</Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 3,
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#252525",
                    color: "#FEE121",
                    fontWeight: "600",
                  }}
                  size="large"
                  onClick={() => handleClickOpen(item)}
                >
                  Book Now
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{ padding: 5 }}>
              <Avatar
                src={item.featuredImage}
                alt=""
                variant="square"
                sx={{ width: "100%", height: "100%" }}
              />
            </Grid>
          </Grid>
        ))}
      </Box>
      <Footer />
    </>
  );
}
