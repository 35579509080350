import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import { Grid, Link, TextField } from "@mui/material";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { db } from "../../firebase";
import Button from "@mui/material/Button";
import { BackgroundColor, TextColorBlack } from "../colors/ColorsScheme";

export default function LoginDialog({ open, setOpen, showcancel }) {
  const [showPassword, setShowPassword] = React.useState(false);

  const [phone, setPhone] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [open1, setOpen1] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose1 = () => {
    setOpen1(false);
    setPassword("");
    setPhone("");
  };

  React.useEffect(() => {
    setOpen(open);
  }, [open]);

  const handleSubmit = async () => {
    const q = query(
      collection(db, "website_customers"),
      where("phone", "==", phone),
      where("password", "==", password)
    );

    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      newData.forEach((object) => {
        delete object["password"];
      });

      if (newData.length === 0) {
        alert("Wrong credentials");
      } else {
        localStorage.setItem("customerdetail", JSON.stringify(newData));
        setOpen(false);
        window.location.reload();
      }
    });
  };

  const handleChangePassword = async () => {
    setLoading(true);

    const q = query(
      collection(db, "website_customers"),
      where("email", "==", email),
      where("phone", "==", phone)
    );

    await getDocs(q).then(async (querySnapshot) => {
      setLoading(false);
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      if (newData.length === 0) {
        setLoading(false);
        alert("wrong detail");
      } else {
        await updateDoc(
          doc(db, "website_customers", newData[0].id),

          {
            password: password,
          }
        );
        setLoading(false);
        setOpen1(false);
        alert("password changed successfully");
      }
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={showcancel === true ? handleClose : null}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Login in your account"}
        </DialogTitle>
        <DialogContent>
          <TextField
            placeholder="Phone number"
            type="number"
            label="Phone number"
            fullWidth
            sx={{ marginBottom: 3, marginTop: 1 }}
            value={phone}
            onChange={(e) =>
              e.target.value.toString().length <= 10
                ? setPhone(e.target.value)
                : null
            }
          />
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <TextField
              placeholder="Password"
              label="Password"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword === true ? "text" : "password"}
            />
            <IconButton
              sx={{ marginLeft: "-3rem" }}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword === true ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </Box>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Button
                onClick={() => {
                  setOpen1(true);
                  setPassword("");
                  setPhone("");
                }}
                sx={{ marginLeft: -1 }}
              >
                Forgot password
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              <Link href="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {showcancel === true ? (
            <Button onClick={handleClose}>Cancel</Button>
          ) : null}
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{ backgroundColor: BackgroundColor, color: TextColorBlack }}
          >
            Login
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open1}
        onClose={handleClose1}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Change your password"}
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            label="Phone"
            autoFocus
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            label="New Password"
            autoFocus
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1}>Cancel</Button>
          <Button
            onClick={handleChangePassword}
            disabled={loading === true ? true : false}
            variant="contained"
          >
            {loading === true ? "Please wait.." : " Change"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
