import React from "react";
import { Avatar, Box, Grid, Link, Typography } from "@mui/material";
import Logo from "../mainHeader/assets/img/fafa.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(name, calories, fat, carbs, protein, protein1, protein2) {
  return { name, calories, fat, carbs, protein, protein1, protein2 };
}

const rows = [createData("Frozen yoghurt", 159, 6.0, 24, 4.0, 5.0, 6.0)];

export const Invoice = React.forwardRef((orderData, ref) => {
  console.log({ orderData: orderData.orderData });
  return (
    <>
      {Object.keys(orderData.orderData).length > 0 ? (
        <Grid container sx={{ padding: 5 }} ref={ref}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Avatar
              src={Logo}
              alt="logo"
              variant="square"
              sx={{ width: 136, height: 65 }}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography sx={{ fontWeight: "700" }}>FAFA PLUS SIZE</Typography>
            <Typography sx={{ fontStyle: "italic" }}>
              Making Plus Size Fabulous
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Typography sx={{ fontSize: 35, fontWeight: "600", color: "grey" }}>
              INVOICE
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} sx={{ marginTop: 3 }}>
            <Typography
              sx={{ fontSize: 13, fontWeight: "600", color: "#484747" }}
            >
              GST - 06BNNPS3927B1ZW
            </Typography>
            <Typography sx={{ fontSize: 12 }}>
              B141, DLF Regal Gardens, Sector 90, Gurugram, HR-122505
            </Typography>
            <Typography sx={{ fontSize: 12 }}>+91 8130582284</Typography>
            <Typography>
              <Link sx={{ fontSize: 12 }}>fafaplususize@gmail.com</Link>
            </Typography>

            <Typography>
              <Link sx={{ fontSize: 12 }}>wwww.fafaplussize.com</Link>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Typography sx={{ fontSize: 12, textTransform: "uppercase" }}>
              INVOICE #{orderData.orderData.id}
            </Typography>
            <Typography sx={{ fontSize: 12 }}>
              DATE: {orderData.orderData.createdDate?.slice(0, 16)}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography sx={{ fontWeight: "600" }}>TO:</Typography>
            <Typography>
              Name: {orderData?.orderData?.shippingAddress[0]?.name}
            </Typography>
            <Typography>Company Name: NA</Typography>
            <Typography>
              Street Address:{" "}
              {orderData?.orderData?.shippingAddress[0]?.streetName}
            </Typography>
            <Typography>
              City, ST ZIP Code:{" "}
              {orderData?.orderData?.shippingAddress[0]?.city},{" "}
              {orderData?.orderData?.shippingAddress[0]?.state}{" "}
              {orderData?.orderData?.shippingAddress[0]?.pincode}
            </Typography>
            <Typography>
              Phone Number: {orderData?.orderData?.shippingAddress[0]?.phone}
            </Typography>
          </Grid>

          <Grid item xs={6} md={6}>
            <Typography sx={{ fontWeight: "600" }}>SHIP TO:</Typography>
            <Typography>
              Name: {orderData?.orderData?.shippingAddress[0]?.name}
            </Typography>
            <Typography>Company Name: NA</Typography>
            <Typography>
              Street Address:{" "}
              {orderData?.orderData?.shippingAddress[0]?.streetName}
            </Typography>
            <Typography>
              {" "}
              City, ST ZIP Code:{" "}
              {orderData?.orderData?.shippingAddress[0]?.city},{" "}
              {orderData?.orderData?.shippingAddress[0]?.state}{" "}
              {orderData?.orderData?.shippingAddress[0]?.pincode}
            </Typography>
            <Typography>
              Phone Number: {orderData?.orderData?.shippingAddress[0]?.phone}
            </Typography>
          </Grid>
          {/* <Grid item xs={12} md={6} sx={{ marginTop: 1 }}>
  <Typography sx={{ fontWeight: "600" }}>
    COMMENTS OR SPECIAL INSTRUCTIONS:
  </Typography>
  <Typography>YOUR COMMENTS: NA</Typography>
</Grid> */}
          <Grid item xs={12} md={12} sx={{ marginTop: 1 }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "600", fontSize: 10 }}>
                      SHIP DATE
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: "600", fontSize: 10 }}
                    >
                      P.O. NUMBER
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: "600", fontSize: 10 }}
                    >
                      TOTAL GROSS WEIGHT
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: "600", fontSize: 10 }}
                    >
                      REQUISITIONER
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: "600", fontSize: 10 }}
                    >
                      SHIPPED VIA
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: "600", fontSize: 10 }}
                    >
                      F.O.B. POINT
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: "600", fontSize: 10 }}
                    >
                      TERMS
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ fontSize: 10 }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="right" sx={{ fontSize: 10 }}>
                        {row.calories}
                      </TableCell>
                      <TableCell align="right" sx={{ fontSize: 10 }}>
                        {row.fat}
                      </TableCell>
                      <TableCell align="right" sx={{ fontSize: 10 }}>
                        {row.carbs}
                      </TableCell>
                      <TableCell align="right" sx={{ fontSize: 10 }}>
                        {row.protein}
                      </TableCell>
                      <TableCell align="right" sx={{ fontSize: 10 }}>
                        {row.protein1}
                      </TableCell>
                      <TableCell align="right" sx={{ fontSize: 10 }}>
                        {row.protein2}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} md={12} sx={{ marginTop: 1 }}>
            <Typography
              sx={{
                fontWeight: "600",
                display: "flex",
                justifyContent: "center",
                marginBottom: 1,
              }}
            >
              ORDER DETAILS
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "600", fontSize: 10 }}>
                      STYLE NO.
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: "600", fontSize: 10 }}
                    >
                      DESCRIPTION
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: "600", fontSize: 10 }}
                    >
                      COLOR
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: "600", fontSize: 10 }}
                    >
                      QUANTITY
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: "600", fontSize: 10 }}
                    >
                      SIZE
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: "600", fontSize: 10 }}
                    >
                      UNIT PRICE
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: "600", fontSize: 10 }}
                    >
                      TOTAL
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderData?.orderData?.orderItems.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ textTransform: "uppercase", fontSize: 10 }}
                      >
                        {row.productId}
                      </TableCell>
                      <TableCell align="right" sx={{ fontSize: 10 }}>
                        {row.productName}
                      </TableCell>
                      <TableCell align="right" sx={{ fontSize: 10 }}>
                        {row?.variants[0]?.variantValue}
                      </TableCell>
                      <TableCell align="right" sx={{ fontSize: 10 }}>
                        {row.qty}
                      </TableCell>
                      <TableCell align="right" sx={{ fontSize: 10 }}>
                        {row.variants[1]?.variantValue}
                      </TableCell>
                      <TableCell align="right" sx={{ fontSize: 10 }}>
                        {row.salePrice}
                      </TableCell>
                      <TableCell align="right" sx={{ fontSize: 10 }}>
                        {row.salePrice * row.qty}
                      </TableCell>
                    </TableRow>
                  ))}

                  <TableRow>
                    <TableCell rowSpan={3} />
                    <TableCell rowSpan={3} />
                    <TableCell rowSpan={3} />
                    <TableCell rowSpan={3} />

                    <TableCell colSpan={2} sx={{ fontSize: 10 }}>
                      Subtotal
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: 10 }}>
                      {orderData?.orderData?.paymentStatus[0]?.totalAmnt}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontSize: 10 }}>Tax</TableCell>
                    <TableCell align="right" sx={{ fontSize: 10 }}>
                      12%
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: 10 }}>
                      {(
                        orderData?.orderData?.paymentStatus[0]?.totalAmnt * 0.12
                      ).toFixed(2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} sx={{ fontSize: 10 }}>
                      Total
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: 10 }}>
                      {parseFloat(
                        orderData?.orderData?.paymentStatus[0]?.totalAmnt
                      ) +
                        parseFloat(
                          orderData?.orderData?.paymentStatus[0]?.totalAmnt *
                            0.12
                        )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={12} sx={{ marginTop: 1 }}>
            <Typography>Make all checks payable to FAFA PLUS SIZE.</Typography>
            <Typography>
              If you have any questions concerning this invoice, contact: hp at
              +91 8130582284 or fafaplususize@gmail.com.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography sx={{ fontWeight: "600" }}>
              THANK YOU FOR YOUR BUSINESS!
            </Typography>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
});
