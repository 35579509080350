import * as React from "react";

import { Avatar, Box, Link, Typography } from "@mui/material";

export default function CategoryCard({ imgSrc, name, stylee, width, height,id }) {
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Link href={`/productlist/${id}`}>
        <Avatar
          src={imgSrc}
          alt=""
          variant={stylee}
          sx={{ width: width, height: height, marginBottom: 1, marginTop: 1 }}
        />
      </Link>
      <Typography
        variant="h5"
        sx={{
          color: "black",
          fontWeight: "500",
          fontSize: 15,
          marginBottom: 1,
        }}
      >
        {name}
      </Typography>
    </Box>
  );
}
