import React from "react";
// import Carousel from "react-grid-carousel";

import CategoryCard from "./Category";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import Carousel from "react-elastic-carousel";
import "./styles.css";
import { Box, Skeleton } from "@mui/material";
const breakPoints = [
  { width: 1, itemsToShow: 3, itemsToScroll: 2 },
  { width: 550, itemsToShow: 6, itemsToScroll: 2 },
  { width: 768, itemsToShow: 8, itemsToScroll: 2 },
  { width: 1200, itemsToShow: 14, itemsToScroll: 2 },
];
export default function CategorySlider({ stylee, width, height }) {
  const [parentCategoryData, setParentCategoryData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    getParentCategory();
  }, []);

  const getParentCategory = async () => {
    setLoading(true);
    await getDocs(collection(db, "dashboard_categories")).then(
      (querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        let list = newData.filter((item) => item.parent !== "");
        setParentCategoryData(list);
      }
    );
    setLoading(false);
  };

  return (
    <div
      style={{
        paddingLeft: 10,
        paddingRight: 10,
        boxShadow: "2px 2px 2px #EEF2F1",
      }}
    >
      {loading === true ? (
        <Box sx={{ width: "100%" }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      ) : (
        <div className="carousel-wrapper">
          <Carousel breakPoints={breakPoints}>
            {parentCategoryData.map((item, index) => (
              <CategoryCard
                key={index}
                imgSrc={item.image}
                name={item.name}
                stylee={stylee}
                width={width}
                height={height}
                id={item.id}
              />
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
}
