import React from "react";
import MainHeader from "../../components/mainHeader/MainHeader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Footer from "../../components/footer/Footer";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import ProductCard from "../../components/caruasalSlider/Product";
import Slider from "@mui/material/Slider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import { Close } from "@mui/icons-material";
import ButtonGroup from "@mui/material/ButtonGroup";
import {
  BackgroundColor,
  TextColorBlack,
} from "../../components/colors/ColorsScheme";

function valuetext(value) {
  return `${value}°C`;
}

export default function ProductList() {
  const [productList, setProductList] = React.useState([]);
  const [producFilteredtList, setFilteredProductList] = React.useState([]);

  const [loading, setLoading] = React.useState(true);
  const [state, setState] = React.useState(false);
  const [subCategoryType, setSubCategoryType] = React.useState([]);
  const [maxPrice, setMaxPrice] = React.useState(0);
  const [minPrice, setMinPrice] = React.useState(0);
  const [sizeList, setSizeList] = React.useState([]);
  const [colorList, setColorList] = React.useState([]);
  const [brandList, setBrandList] = React.useState([]);
  const [fitList, setFitList] = React.useState([]);
  const [fabricList, setFabricList] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [rating, setRating] = React.useState([]);
  const [discount, setDsicount] = React.useState([]);

  React.useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    setLoading(true);
    const q = query(
      collection(db, "dashboard_product"),
      where("status", "==", true),
      where("qaChecked", "==", true),
      where("isRejected", "==", false)
    );
    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      let list = newData.filter(
        (el) =>
          el.category.id ===
          window.location.pathname.replace("/productlist/", "")
      );

      const size = list?.map((e) => e.attributes?.Size?.value);

      setSizeList(size.flat());
      const color = list.map((e) => e.variants.map((ee) => ee.value));
      setColorList(color.flat());
      const brand = list.map((e) => e.productBrand);
      setBrandList(brand.flat());

      const fit = list.map((e) => e.fit);
      setFitList(fit.flat());
      const fabric = list.map((e) => e.fabric);
      setFabricList(fabric.flat());

      const discountt = list.map((e) =>
        (100 - (e.productSalePrice / e.productBasePrice) * 100).toFixed(0)
      );

      setDsicount(discountt.flat().sort());

      const rating = list.map((e) =>
        e.productRating?.map((ee) => ee.ratingCount)
      );
      setRating(rating.flat());

      const subcategory = list.map((e) => e.categoryType);
      setSubCategoryType(subcategory.flat());

      setProductList(list);
      setFilteredList([...filteredList, list[0]?.category.name]);
      if (list.length > 1) {
        let maxValue = list.reduce((acc, value) => {
          return (acc =
            acc > parseInt(value.productSalePrice)
              ? acc
              : parseInt(value.productSalePrice));
        }, 0);
        setMaxPrice(maxValue);

        let minValue = list.reduce((acc, value) => {
          return (acc =
            acc < parseInt(value.productSalePrice)
              ? acc
              : parseInt(value.productSalePrice));
        });

        setMinPrice(minValue);
      } else {
        setMaxPrice(parseInt(list[0]?.productSalePrice));
        setMinPrice(parseInt(list[0]?.productSalePrice));
      }
    });

    setLoading(false);
  };

  const handleDelete = (idx, item) => {
    if (idx !== 0) {
      let list = [...filteredList];
      list.splice(idx, 1);
      setFilteredList(list);
      let isInArray = producFilteredtList.filter(
        (e) => !JSON.stringify(e).includes(item)
      );

      setFilteredProductList(isInArray);
    }
  };

  const handleAddFilteredList = (item, type) => {
    if (type !== "price" && !filteredList.includes(item)) {
      setFilteredList([...filteredList, item]);
    }
    if (type === "categoryType") {
      if (filteredList.includes(item)) {
      } else {
        let isInArray = productList.filter((e) => e.categoryType === item);
        let a = producFilteredtList.concat(isInArray);

        setFilteredProductList(a);
      }
    }

    if (type === "size") {
      if (filteredList.includes(item)) {
      } else {
        let isInArray = productList.filter((e) =>
          JSON.stringify(e.attributes.Size.value).includes(item)
        );

        const filterByReference = (arr1, arr2) => {
          let res = [];
          res = arr1.filter((el) => {
            return !arr2.find((element) => {
              return element.id === el.id;
            });
          });
          return res;
        };

        let a = producFilteredtList.concat(
          filterByReference(isInArray, producFilteredtList)
        );
        setFilteredProductList(a);
      }
    }

    if (type === "color") {
      if (filteredList.includes(item)) {
      } else {
        let isInArray = productList.filter((e) =>
          JSON.stringify(e.variants).includes(item)
        );

        const filterByReference = (arr1, arr2) => {
          let res = [];
          res = arr1.filter((el) => {
            return !arr2.find((element) => {
              return element.id === el.id;
            });
          });
          return res;
        };

        let a = producFilteredtList.concat(
          filterByReference(isInArray, producFilteredtList)
        );
        setFilteredProductList(a);
      }
    }

    if (type === "brand") {
      if (filteredList.includes(item)) {
      } else {
        let isInArray = productList.filter((e) =>
          JSON.stringify(e.productBrand).includes(item)
        );

        const filterByReference = (arr1, arr2) => {
          let res = [];
          res = arr1.filter((el) => {
            return !arr2.find((element) => {
              return element.id === el.id;
            });
          });
          return res;
        };

        let a = producFilteredtList.concat(
          filterByReference(isInArray, producFilteredtList)
        );
        setFilteredProductList(a);
      }
    }

    if (type === "fit") {
      if (filteredList.includes(item)) {
      } else {
        let isInArray = productList.filter((e) =>
          JSON.stringify(e.fit).includes(item)
        );

        const filterByReference = (arr1, arr2) => {
          let res = [];
          res = arr1.filter((el) => {
            return !arr2.find((element) => {
              return element.id === el.id;
            });
          });
          return res;
        };

        let a = producFilteredtList.concat(
          filterByReference(isInArray, producFilteredtList)
        );
        setFilteredProductList(a);
      }
    }

    if (type === "fabric") {
      if (filteredList.includes(item)) {
      } else {
        let isInArray = productList.filter((e) =>
          JSON.stringify(e.fabric).includes(item)
        );

        const filterByReference = (arr1, arr2) => {
          let res = [];
          res = arr1.filter((el) => {
            return !arr2.find((element) => {
              return element.id === el.id;
            });
          });
          return res;
        };

        let a = producFilteredtList.concat(
          filterByReference(isInArray, producFilteredtList)
        );
        setFilteredProductList(a);
      }
    }

    if (type === "price") {
      let isInArray = productList.filter(
        (e) =>
          parseInt(e.productSalePrice) <= item &&
          parseInt(e.productSalePrice) >= minPrice
      );

      setFilteredProductList(isInArray);
    }

    if (type === "rating") {
      if (filteredList.includes(item)) {
      } else {
        let isInArray = productList.filter(
          (e) => e.productRating[0]?.ratingCount === item
        );

        const filterByReference = (arr1, arr2) => {
          let res = [];
          res = arr1.filter((el) => {
            return !arr2.find((element) => {
              return element.id === el.id;
            });
          });
          return res;
        };

        let a = producFilteredtList.concat(
          filterByReference(isInArray, producFilteredtList)
        );
        setFilteredProductList(a);
      }
    }

    if (type === "discount") {
      if (filteredList.includes(item)) {
      } else {
        let isInArray = productList.filter(
          (e) =>
            (100 - (e.productSalePrice / e.productBasePrice) * 100).toFixed(
              0
            ) <= item
        );

        const filterByReference = (arr1, arr2) => {
          let res = [];
          res = arr1.filter((el) => {
            return !arr2.find((element) => {
              return element.id === el.id;
            });
          });
          return res;
        };

        let a = producFilteredtList.concat(
          filterByReference(isInArray, producFilteredtList)
        );
        setFilteredProductList(a);
      }
    }
  };


  return (
    <>
      <MainHeader />
      {productList.length === 0 ? (
        <Box sx={{ p: 20, display: "flex", justifyContent: "center" }}>
          <Typography variant="h5">No any product</Typography>
        </Box>
      ) : (
        <Grid container sx={{ marginTop: 3, paddingLeft: 1, paddingRight: 1 }}>
          <Grid item xs={12} md={3}>
            <Paper sx={{ p: 1 }}>
              <Box
                sx={{
                  width: "100%",
                  maxHeight: "80vh",
                  overflowY: "auto",
                  display: { xs: "none", md: "block" },
                }}
              >
                <Typography variant="h6" sx={{ marginBottom: 2 }}>
                  Filters
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  gap={1}
                  sx={{ flexWrap: "wrap", marginBottom: 2 }}
                >
                  {filteredList
                    .filter(
                      (item, index) => filteredList.indexOf(item) === index
                    )
                    .map((item, idx) => (
                      <Chip
                        label={item}
                        onDelete={() => handleDelete(idx, item)}
                        key={idx}
                      />
                    ))}
                </Stack>
                <Box sx={{ p: 2 }}>
                  <Typography>Price</Typography>
                  <Slider
                    aria-label="Temperature"
                    defaultValue={minPrice}
                    getAriaValueText={valuetext}
                    valueLabelDisplay="auto"
                    step={200}
                    marks
                    min={minPrice}
                    max={maxPrice}
                    onChange={(e) =>
                      handleAddFilteredList(e.target.value, "price")
                    }
                  />
                </Box>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{productList[0].category.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <nav aria-label="secondary mailbox folders">
                      <List>
                        {subCategoryType
                          .filter(
                            (item, index) =>
                              subCategoryType.indexOf(item) === index
                          )
                          .map((item, index) => (
                            <ListItem disablePadding key={index}>
                              <ListItemButton
                                onClick={() =>
                                  handleAddFilteredList(item, "categoryType")
                                }
                              >
                                <ListItemText primary={item} />
                              </ListItemButton>
                            </ListItem>
                          ))}
                      </List>
                    </nav>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Size</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <nav aria-label="secondary mailbox folders">
                      <List>
                        {sizeList
                          .filter(
                            (item, index) => sizeList.indexOf(item) === index
                          )
                          .map((e, idx) => (
                            <ListItem
                              disablePadding
                              key={idx}
                              onClick={() => handleAddFilteredList(e, "size")}
                            >
                              <ListItemButton>
                                <ListItemText primary={e} />
                              </ListItemButton>
                            </ListItem>
                          ))}
                      </List>
                    </nav>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Color</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <nav aria-label="secondary mailbox folders">
                      <List>
                        {colorList
                          .filter(
                            (item, index) => colorList.indexOf(item) === index
                          )
                          .map((item, idx) => (
                            <ListItem
                              disablePadding
                              key={idx}
                              onClick={() =>
                                handleAddFilteredList(item, "color")
                              }
                            >
                              <ListItemButton>
                                <ListItemText primary={item} />
                              </ListItemButton>
                            </ListItem>
                          ))}
                      </List>
                    </nav>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Discount</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <nav aria-label="secondary mailbox folders">
                      <List>
                        {discount
                          .filter(
                            (item, index) => discount.indexOf(item) === index
                          )

                          .map((item, idx) => (
                            <ListItem
                              disablePadding
                              key={idx}
                              onClick={() =>
                                handleAddFilteredList(item, "discount")
                              }
                            >
                              <ListItemButton>
                                <ListItemText primary={`Upto ${item}%`} />
                              </ListItemButton>
                            </ListItem>
                          ))}
                      </List>
                    </nav>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Brand</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <nav aria-label="secondary mailbox folders">
                      <List>
                        {brandList
                          .filter(
                            (item, index) => brandList.indexOf(item) === index
                          )
                          .map((item, idx) => (
                            <ListItem
                              disablePadding
                              key={idx}
                              onClick={() =>
                                handleAddFilteredList(item, "brand")
                              }
                            >
                              <ListItemButton>
                                <ListItemText primary={item} />
                              </ListItemButton>
                            </ListItem>
                          ))}
                      </List>
                    </nav>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Customer rating</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <nav aria-label="secondary mailbox folders">
                      <List>
                        {rating
                          .filter(
                            (item, index) =>
                              rating.indexOf(item) === index &&
                              item !== undefined
                          )
                          .map((item, idx) => (
                            <ListItem
                              disablePadding
                              key={idx}
                              onClick={() =>
                                handleAddFilteredList(item, "rating")
                              }
                            >
                              <ListItemButton>
                                <ListItemText primary={`${item}★ & above`} />
                              </ListItemButton>
                            </ListItem>
                          ))}
                      </List>
                    </nav>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Fit</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <nav aria-label="secondary mailbox folders">
                      <List>
                        {fitList
                          .filter(
                            (item, index) => fitList.indexOf(item) === index
                          )
                          .map((item, idx) => (
                            <ListItem
                              disablePadding
                              key={idx}
                              onClick={() => handleAddFilteredList(item, "fit")}
                            >
                              <ListItemButton>
                                <ListItemText primary={item} />
                              </ListItemButton>
                            </ListItem>
                          ))}
                      </List>
                    </nav>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Fabric</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <nav aria-label="secondary mailbox folders">
                      <List>
                        {fabricList
                          .filter(
                            (item, index) => fabricList.indexOf(item) === index
                          )
                          .map((item, idx) => (
                            <ListItem
                              disablePadding
                              key={idx}
                              onClick={() =>
                                handleAddFilteredList(item, "fabric")
                              }
                            >
                              <ListItemButton>
                                <ListItemText primary={item} />
                              </ListItemButton>
                            </ListItem>
                          ))}
                      </List>
                    </nav>
                  </AccordionDetails>
                </Accordion>
              </Box>

              <Button
                onClick={() => setState(true)}
                sx={{
                  display: { md: "none" },
                  backgroundColor: BackgroundColor,
                  color: TextColorBlack,
                }}
                variant="contained"
                size="small"
              >
                Filter
              </Button>
              <Drawer
                anchor="left"
                open={state}
                onClose={() => setState(false)}
              >
                <Box
                  sx={{
                    maxHeight: "100vh",
                    overflowY: "auto",
                    p: 1,
                    width: 300,
                  }}
                >
                  <Button
                    endIcon={<Close />}
                    variant="contained"
                    sx={{
                      display: "flex",
                      marginBottom: 3,
                      width: "100%",
                      backgroundColor: BackgroundColor,
                      color: TextColorBlack,
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    onClick={() => setState(false)}
                  >
                    Filter
                  </Button>

                  <Stack
                    direction="row"
                    spacing={1}
                    gap={1}
                    sx={{ flexWrap: "wrap", marginBottom: 2 }}
                  >
                    {filteredList
                      .filter(
                        (item, index) => filteredList.indexOf(item) === index
                      )
                      .map((item, idx) => (
                        <Chip
                          label={item}
                          onDelete={() => handleDelete(idx, item)}
                          key={idx}
                        />
                      ))}
                  </Stack>
                  <Box sx={{ p: 2 }}>
                    <Typography>Price</Typography>
                    <Slider
                      aria-label="Temperature"
                      defaultValue={minPrice}
                      getAriaValueText={valuetext}
                      valueLabelDisplay="auto"
                      step={200}
                      marks
                      min={minPrice}
                      max={maxPrice}
                      onChange={(e) =>
                        handleAddFilteredList(e.target.value, "price")
                      }
                    />
                  </Box>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>{productList[0].category.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <nav aria-label="secondary mailbox folders">
                        <List>
                          {subCategoryType
                            .filter(
                              (item, index) =>
                                subCategoryType.indexOf(item) === index
                            )
                            .map((item, index) => (
                              <ListItem disablePadding key={index}>
                                <ListItemButton
                                  onClick={() =>
                                    handleAddFilteredList(item, "categoryType")
                                  }
                                >
                                  <ListItemText primary={item} />
                                </ListItemButton>
                              </ListItem>
                            ))}
                        </List>
                      </nav>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Size</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <nav aria-label="secondary mailbox folders">
                        <List>
                          {sizeList
                            .filter(
                              (item, index) => sizeList.indexOf(item) === index
                            )
                            .map((e, idx) => (
                              <ListItem
                                disablePadding
                                key={idx}
                                onClick={() => handleAddFilteredList(e, "size")}
                              >
                                <ListItemButton>
                                  <ListItemText primary={e} />
                                </ListItemButton>
                              </ListItem>
                            ))}
                        </List>
                      </nav>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Color</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <nav aria-label="secondary mailbox folders">
                        <List>
                          {colorList
                            .filter(
                              (item, index) => colorList.indexOf(item) === index
                            )
                            .map((item, idx) => (
                              <ListItem
                                disablePadding
                                key={idx}
                                onClick={() =>
                                  handleAddFilteredList(item, "color")
                                }
                              >
                                <ListItemButton>
                                  <ListItemText primary={item} />
                                </ListItemButton>
                              </ListItem>
                            ))}
                        </List>
                      </nav>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Discount</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <nav aria-label="secondary mailbox folders">
                        <List>
                          {discount
                            .filter(
                              (item, index) => discount.indexOf(item) === index
                            )

                            .map((item, idx) => (
                              <ListItem
                                disablePadding
                                key={idx}
                                onClick={() =>
                                  handleAddFilteredList(item, "discount")
                                }
                              >
                                <ListItemButton>
                                  <ListItemText primary={`Upto ${item}%`} />
                                </ListItemButton>
                              </ListItem>
                            ))}
                        </List>
                      </nav>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Brand</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <nav aria-label="secondary mailbox folders">
                        <List>
                          {brandList
                            .filter(
                              (item, index) => brandList.indexOf(item) === index
                            )
                            .map((item, idx) => (
                              <ListItem
                                disablePadding
                                key={idx}
                                onClick={() =>
                                  handleAddFilteredList(item, "brand")
                                }
                              >
                                <ListItemButton>
                                  <ListItemText primary={item} />
                                </ListItemButton>
                              </ListItem>
                            ))}
                        </List>
                      </nav>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Customer rating</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <nav aria-label="secondary mailbox folders">
                        <List>
                          {rating
                            .filter(
                              (item, index) =>
                                rating.indexOf(item) === index &&
                                item !== undefined
                            )
                            .map((item, idx) => (
                              <ListItem
                                disablePadding
                                key={idx}
                                onClick={() =>
                                  handleAddFilteredList(item, "rating")
                                }
                              >
                                <ListItemButton>
                                  <ListItemText primary={`${item}★ & above`} />
                                </ListItemButton>
                              </ListItem>
                            ))}
                        </List>
                      </nav>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Fit</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <nav aria-label="secondary mailbox folders">
                        <List>
                          {fitList
                            .filter(
                              (item, index) => fitList.indexOf(item) === index
                            )
                            .map((item, idx) => (
                              <ListItem
                                disablePadding
                                key={idx}
                                onClick={() =>
                                  handleAddFilteredList(item, "fit")
                                }
                              >
                                <ListItemButton>
                                  <ListItemText primary={item} />
                                </ListItemButton>
                              </ListItem>
                            ))}
                        </List>
                      </nav>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Fabric</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <nav aria-label="secondary mailbox folders">
                        <List>
                          {fabricList
                            .filter(
                              (item, index) =>
                                fabricList.indexOf(item) === index
                            )
                            .map((item, idx) => (
                              <ListItem
                                disablePadding
                                key={idx}
                                onClick={() =>
                                  handleAddFilteredList(item, "fabric")
                                }
                              >
                                <ListItemButton>
                                  <ListItemText primary={item} />
                                </ListItemButton>
                              </ListItem>
                            ))}
                        </List>
                      </nav>
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <ButtonGroup
                  aria-label="outlined primary button group"
                  sx={{
                    p: 1,
                    width: "95%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{ width: "49%" }}
                    onClick={() => setState(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      width: "49%",
                      backgroundColor: "#FB641B",
                      backgroundColor: BackgroundColor,
                      color: TextColorBlack,
                    }}
                    onClick={() => setState(false)}
                  >
                    Apply
                  </Button>
                </ButtonGroup>
              </Drawer>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: { xs: 0, md: 1 },
              }}
            >
              {producFilteredtList.length > 0
                ? producFilteredtList.map((slide, index) => (
                    <Grid item xs={6} md={3} sm={4} lg={4} xl={2} key={index}>
                      <Paper elevation={0}>
                        <ProductCard slide={slide} productList={true} />
                      </Paper>
                    </Grid>
                  ))
                : productList.map((slide, index) => (
                    <Grid item xs={6} md={4} sm={4} lg={3} xl={2} key={index}>
                      <Paper elevation={0}>
                        <ProductCard slide={slide} productList={true} />
                      </Paper>
                    </Grid>
                  ))}
            </Box>
          </Grid>
        </Grid>
      )}
      <Footer />
    </>
  );
}
