import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Checkbox from "@mui/material/Checkbox";
import { useLocation } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MainHeader from "../../components/mainHeader/MainHeader";
import { Avatar } from "@mui/material";
import {
  Grid,
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Chip,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Add } from "@mui/icons-material";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import useRazorpay from "react-razorpay";

const steps = ["Shipping address", "Payment details", "Review your order"];


export default function Checkout() {
  const location = useLocation();
  let customerdetail = localStorage.getItem("customerdetail");
  const [Razorpay] = useRazorpay();
  const [activeStep, setActiveStep] = React.useState(0);
  const [products, setProducts] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [addressList, setAddressList] = React.useState([]);
  const [addressType, setAddressType] = React.useState("");
  const [addNewAddress, setAddNewAddress] = React.useState(false);
  const [name, setName] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [pincode, setPincode] = React.useState("");
  const [locality, setLocality] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [landmark, setLandmark] = React.useState("");
  const [alternateMobile, setAlternateMobile] = React.useState("");
  const [addressData, setAddressData] = React.useState({});
  const [checkedIndex, setCheckedIndex] = React.useState(0);
  const [paymentMethod, setPaymentMethod] = React.useState("Online");

  const handleCheckAddress = (item, index) => {
    setAddressData(item);
    setCheckedIndex(index);
  };

  const handleCheckPaymentMethod = (method) => {
    setPaymentMethod(method);
  };

  const handleChangeAddressType = (event) => {
    setAddressType(event.target.value);
  };

  React.useEffect(() => {
    getCustomerData();
  }, []);

  const getCustomerData = async () => {
    const snap = await getDoc(
      doc(db, "website_customers", JSON.parse(customerdetail)[0].id)
    );

    if (snap.exists()) {
      setAddressList(snap.data().address);
      setAddressData(snap.data().address[1]);
    }
  };

  const handleSubmit = async () => {
    let a = [
      {
        name: name,
        mobile: mobile,
        pincode: pincode,
        locality: locality,
        street: street,
        city: city,
        state: state,
        landmark: landmark,
        alternateMobile: alternateMobile,
        addressType: addressType,
      },
    ];
    let arr = addressList.concat(a);
    if (
      name !== "" &&
      mobile !== "" &&
      pincode !== "" &&
      locality !== "" &&
      street !== "" &&
      city !== "" &&
      state !== "" &&
      addressType !== ""
    ) {
      setLoading(true);
      await updateDoc(
        doc(db, "website_customers", JSON.parse(customerdetail)[0].id),
        {
          address: arr,
        }
      );
      setLoading(false);
      window.location.reload();
    } else {
      alert("Required fields can not be empty");
      setLoading(false);
    }
  };

  const handleAddNewAddress = () => {
    setAddNewAddress(!addNewAddress);
    setName("");
    setMobile("");
    setPincode("");
    setLocality("");
    setStreet("");
    setCity("");
    setState("");
    setLandmark("");
    setAlternateMobile("");
    setAddressType("");
  };
  const handleNext = () => {
    if (activeStep === 0 && addressData === undefined) {
      alert("Please add shipping address");
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleRemove = async (id) => {
    setLoading(true);
    const docRef = doc(db, "website_cart", id);

    deleteDoc(docRef)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handlePlaceOrder = async () => {
    const options = {
      key: "rzp_live_ZeXWQqVCFd0RSZ",
      amount: `${parseInt(location.state.totalPrice) * 100}`,
      currency: "INR",
      name: "FAFA PLUS SIZE",
      description: "Test Transaction",
      image: "https://cdn.razorpay.com/logos/IYpIjjwXXZ2oVH_medium.png",
      // order_id: "order_9A33XWu170gUtm",
      handler: (res) => {
        if (res.status_code === 200) {
          handleCreateOrder(res.razorpay_payment_id);
        }
      },
      prefill: {
        name: JSON.parse(customerdetail)[0].name,
        email: JSON.parse(customerdetail)[0].email,
        contact: JSON.parse(customerdetail)[0].phone,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  };

  const handleCreateOrder = async (paymentId) => {
    var date = new Date();
    date.setDate(date.getDate() + 7);

    setLoading(true);
    const orders = await Promise.all(
      products.map((item) => {
        const data = [
          {
            productName: item.productName,
            productId: item.id,
            thumb: item.thumb,
            deliveryDate: date.toString(),
            cancelDate: "",
            shippedDate: "",
            outForDeliveryDate: "",
            salePrice: item.productSalePrice,

            sku: item.variants[0].sku,
            qty: item.qty,
            variants: [
              { variantValue: item.color, variantKey: "Color" },
              { variantValue: item.size, variantKey: "Size" },
            ],

            vendorId: item.vendorId,
            itemStatus: "Pending",
            couponcode:
              item.code !== undefined
                ? item.code === "0"
                  ? ""
                  : item.code
                : "",
            codevalue: item.codevalue !== undefined ? item.codevalue : "",
            codetype: item.codetype !== undefined ? item.codetype : "",
          },
        ];
        return data;
      })
    );

    await addDoc(collection(db, "dashboard_orders"), {
      customerId: JSON.parse(customerdetail)[0].id,
      orderItems: orders.flat(),
      status: "Pending",
      shippingAddress: [
        {
          name: addressData.name,
          email: JSON.parse(customerdetail)[0].email,
          phone: addressData.mobile,
          state: addressData.state,
          country: "india",
          pincode: addressData.pincode,
          landMark: addressData.landmark,
          city: addressData.city,
          streetName: addressData.street,
        },
      ],
      paymentStatus: [
        {
          method: paymentMethod,
          paymentStatus: paymentMethod === "Online" ? "Done" : "Pending",
          totalAmnt: location.state.totalPrice,
          transactionId: paymentMethod === "Online" ? paymentId : "CODID",
          failReason: "",
          paymentProvider: "",
          createdDate: new Date().toString(),
        },
      ],
      createdDate: new Date().toString(),
    });
    if (products[0]?.cartid !== undefined) {
      await Promise.all(products.map((item) => handleRemove(item.cartid)));
    }
    setLoading(false);
    setActiveStep(activeStep + 1);

    capturePayment(paymentId);
  };

  const capturePayment = async (paymentId) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Basic Og==");

    var raw = JSON.stringify({
      amount: 100,
      currency: "INR",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(
      `https://api.razorpay.com/v1/payments/${paymentId}/capture`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };


  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <React.Fragment>
            <Typography variant="h5" sx={{ p: 3 }}>
              My address
            </Typography>
            <Grid container>
              <Grid item xs={12} md={4} sx={{ p: 3, marginTop: 1 }}>
                <Paper sx={{ p: 3 }}>
                  <Button
                    variant="text"
                    sx={{ textTransform: "capitalize", p: 2 }}
                    startIcon={<Add />}
                    onClick={() => handleAddNewAddress()}
                  >
                    Add new address
                  </Button>
                  {addNewAddress === true ? (
                    <>
                      <TextField
                        label="Name*"
                        fullWidth
                        sx={{ marginTop: 2 }}
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      />
                      <TextField
                        label="10-digit mobile number*"
                        fullWidth
                        type="number"
                        sx={{ marginTop: 2 }}
                        onChange={(e) =>
                          e.target.value.toString().length <= 10
                            ? setMobile(e.target.value)
                            : null
                        }
                        value={mobile}
                      />
                      <TextField
                        label="Pincode*"
                        fullWidth
                        sx={{ marginTop: 2 }}
                        type="number"
                        onChange={(e) =>
                          e.target.value.toString().length <= 6
                            ? setPincode(e.target.value)
                            : null
                        }
                        value={pincode}
                      />
                      <TextField
                        label="Locality*"
                        fullWidth
                        sx={{ marginTop: 2 }}
                        onChange={(e) => setLocality(e.target.value)}
                        value={locality}
                      />

                      <TextField
                        label="Address (Area & Street)*"
                        fullWidth
                        sx={{ marginTop: 2 }}
                        onChange={(e) => setStreet(e.target.value)}
                        value={street}
                      />
                      <TextField
                        label="City*"
                        fullWidth
                        sx={{ marginTop: 2 }}
                        onChange={(e) => setCity(e.target.value)}
                        value={city}
                      />
                      <TextField
                        label="State*"
                        fullWidth
                        sx={{ marginTop: 2 }}
                        onChange={(e) => setState(e.target.value)}
                        value={state}
                      />
                      <TextField
                        label="Landmark"
                        fullWidth
                        sx={{ marginTop: 2 }}
                        onChange={(e) => setLandmark(e.target.value)}
                        value={landmark}
                      />
                      <TextField
                        label="Alternate mobile number"
                        fullWidth
                        sx={{ marginTop: 2 }}
                        onChange={(e) => setAlternateMobile(e.target.value)}
                        value={alternateMobile}
                      />
                      <FormControl sx={{ marginTop: 2 }}>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Address type*
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          onChange={handleChangeAddressType}
                          value={addressType}
                        >
                          <FormControlLabel
                            value="home"
                            control={<Radio />}
                            label="Home"
                          />
                          <FormControlLabel
                            value="work"
                            control={<Radio />}
                            label="Work"
                          />
                        </RadioGroup>
                      </FormControl>
                      <Box sx={{ marginTop: 2 }}>
                        <Button
                          variant="contained"
                          sx={{ marginRight: 2 }}
                          onClick={handleSubmit}
                          disabled={loading === true ? true : false}
                        >
                          {loading === true ? "Please wait..." : "Save"}
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => setAddNewAddress(!addNewAddress)}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </>
                  ) : null}
                </Paper>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                sx={{ p: 3, maxHeight: "70vh", overflowY: "auto" }}
              >
                {addressList.slice(1).map((item, index) => (
                  <Paper sx={{ p: 3, marginTop: 1 }} key={index}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Chip label={item.addressType} />

                      <Checkbox
                        color="primary"
                        checked={checkedIndex === index ? true : false}
                        onChange={() => handleCheckAddress(item, index)}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 2,
                      }}
                    >
                      <Typography
                        sx={{ marginRight: 3, fontSize: 17 }}
                        variant="h6"
                      >
                        {item.name}
                      </Typography>
                      <Typography sx={{ fontSize: 17 }} variant="h6">
                        {item.mobile}
                      </Typography>
                    </Box>
                    <Typography sx={{ fontSize: 15 }}>
                      {item.locality +
                        ", " +
                        item.street +
                        ", " +
                        item.city +
                        ", " +
                        item.state}
                    </Typography>
                    <Typography sx={{ fontSize: 15 }} variant="h6">
                      {item.pincode}
                    </Typography>
                  </Paper>
                ))}
              </Grid>
            </Grid>
          </React.Fragment>
        );
      case 1:
        return (
          <React.Fragment>
            <Typography variant="h6" gutterBottom>
              Payment method
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      name="saveCard"
                      value="yes"
                      checked={paymentMethod === "Online" ? true : false}
                      onChange={() => handleCheckPaymentMethod("Online")}
                    />
                  }
                  label="Online"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      name="saveCard"
                      value="yes"
                      checked={paymentMethod === "COD" ? true : false}
                      onChange={() => handleCheckPaymentMethod("COD")}
                    />
                  }
                  label="COD"
                />
              </Grid>
            </Grid>
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            <Typography variant="h6" gutterBottom>
              Order summary
            </Typography>
            <List disablePadding>
              {products.map((product, index) => (
                <ListItem key={index} sx={{ py: 1, px: 0 }}>
                  <Avatar
                    src={product.thumb}
                    alt=""
                    variant="rounded"
                    sx={{ width: 56, height: 56, marginRight: 2 }}
                  />

                  <ListItemText
                    primary={product.productName}
                    secondary={`Qty: ${product.qty}, Size: ${product.size}, Color: ${product.color}`}
                  />
                  {location.state.totalDiscount !== undefined ? (
                    <Typography variant="body2">
                      ₹
                      {product.codetype === "percentage"
                        ? (
                            product.productSalePrice * product.qty -
                            product.productSalePrice *
                              product.qty *
                              (product.codevalue / 100)
                          ).toFixed(0)
                        : product.productSalePrice * product.qty -
                          product.codevalue}
                    </Typography>
                  ) : null}
                </ListItem>
              ))}
              <ListItem sx={{ py: 1, px: 0 }}>
                <ListItemText primary="Total" />
                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                  ₹{location.state.totalPrice}
                </Typography>
              </ListItem>
            </List>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                  Shipping
                </Typography>
                <Typography gutterBottom>{addressData.name}</Typography>
                <Typography gutterBottom>
                  {addressData.locality +
                    ", " +
                    addressData.street +
                    ", " +
                    addressData.city +
                    ", " +
                    addressData.state +
                    ", " +
                    addressData.pincode}
                </Typography>
              </Grid>
              <Grid item container direction="column" xs={12} sm={6}>
                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                  Payment details
                </Typography>
                <Grid container>
                  <React.Fragment>
                    <Grid item xs={6}>
                      <Typography gutterBottom>{paymentMethod}</Typography>
                    </Grid>
                  </React.Fragment>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        );
      default:
        throw new Error("Unknown step");
    }
  }

  React.useEffect(() => {
    setProducts(location.state.cart);
  }, []);

  return (
    <>
      <MainHeader />
      <React.Fragment>
        <CssBaseline />

        <Container component="main" sx={{ mb: 4 }}>
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              Checkout
            </Typography>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Thank you for your order.
                </Typography>
                <Typography variant="subtitle1">
                  Your order has been confirmed. We have emailed your order
                  confirmation, and will send you an update when your order has
                  shipped.
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                      Back
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    onClick={
                      activeStep === steps.length - 1
                        ? paymentMethod === "Online"
                          ? handlePlaceOrder
                          : handleCreateOrder
                        : handleNext
                    }
                    disabled={loading === true ? true : false}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {activeStep === steps.length - 1
                      ? loading === true
                        ? "Please wait..."
                        : "Place order"
                      : "Next"}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Paper>
        </Container>
      </React.Fragment>
    </>
  );
}
