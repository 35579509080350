import React from "react";
import ProductCard from "./Product";

// import Carousel from "react-grid-carousel";
import Carousel from "react-elastic-carousel";

const colss = [
  {
    breakpoint: 500,
    cols: 3,
    rows: 1,
    autoplay: 3000,
  },
  {
    breakpoint: 600,
    cols: 3,
    rows: 1,
    autoplay: 3000,
  },
  {
    breakpoint: 700,
    cols: 4,
    rows: 1,
    autoplay: 3000,
  },
  {
    breakpoint: 800,
    cols: 4,
    rows: 1,
    autoplay: 3000,
  },
  {
    breakpoint: 900,
    cols: 4,
    rows: 1,
    autoplay: 3000,
  },
  {
    breakpoint: 1000,
    cols: 4,
    rows: 1,
    autoplay: 3000,
  },
  {
    breakpoint: 1500,
    cols: 4,
    rows: 1,
    autoplay: 3000,
  },
];

export default function ProductSlider({ productlist, cols }) {
  const breakPoints = [
    { width: 375, itemsToShow: 1, itemsToScroll: 2 },
    { width: 500, itemsToShow: 3, itemsToScroll: 2 },
    { width: 768, itemsToShow: cols, itemsToScroll: 2 },
    { width: 1200, itemsToShow: cols, itemsToScroll: 2 },
    { width: 1800, itemsToShow: 6, itemsToScroll: 2 },
    { width: 1500, itemsToShow: 5, itemsToScroll: 2 },
    { width: 1300, itemsToShow: 5, itemsToScroll: 2 },
  ];
  return (
    <Carousel breakPoints={breakPoints}>
      {productlist.map((slide, index) => {
        return <ProductCard slide={slide} key={index} />;
      })}
    </Carousel>
  );
}
