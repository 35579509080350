import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";

import MoreIcon from "@mui/icons-material/MoreVert";
import { Avatar, Button, Grid, Link, Paper } from "@mui/material";
import Logo from "./assets/img/fafa.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Close, Favorite, ShoppingCart, Storefront } from "@mui/icons-material";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

import CategorySlider from "../caruasalSlider/CategoryCaruasal";
import { db } from "../../firebase";
import { DeviceUUID } from "device-uuid";

import LoginDialog from "../loginDialog/LoginDialog";
import { useNavigate } from "react-router-dom";
import { BackgroundColor, TextColorBlack } from "../colors/ColorsScheme";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "23vw",
    },
  },
}));

export default function MainHeader() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [cartCount, setCartCount] = React.useState(0);
  const [wishlistCount, setWishlistCount] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [searchProduct, setSearchProduct] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const handleProfileMenuOpen = (event) => {
    let customerdetail = localStorage.getItem("customerdetail");

    if (customerdetail === null) {
      setOpen(true);
    } else {
      setAnchorEl(event.currentTarget);
      setName(JSON.parse(localStorage.getItem("customerdetail"))[0].name);
    }
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = (path) => {
    setAnchorEl(false);
    handleMobileMenuClose();
    navigate(path);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleWishlist = () => {
    if (customerdetail !== null) {
      navigate("/wishlist");
    } else {
      setOpen(true);
    }
  };

  const handleCart = () => {
    navigate("/cart");
  };

  const handleLogout = () => {
    setAnchorEl(null);

    localStorage.clear();
    window.location.reload();
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={() => setAnchorEl(false)}
    >
      <MenuItem onClick={() => handleMenuClose("/profile")}>
        My profile
      </MenuItem>
      <MenuItem onClick={() => handleMenuClose("/orders")}>My orders</MenuItem>
      <MenuItem onClick={() => handleMenuClose("/address")}>
        Manage address
      </MenuItem>

      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={() => setMobileMoreAnchorEl(false)}
    >
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 4 new mails"
          color="inherit"
          onClick={handleWishlist}
        >
          <Badge badgeContent={wishlistCount} color="error">
            <Favorite />
          </Badge>
        </IconButton>
        <p onClick={handleWishlist}>Wishlist</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
          onClick={handleCart}
        >
          <Badge badgeContent={cartCount} color="error">
            <ShoppingCart />
          </Badge>
        </IconButton>
        <p onClick={handleCart}>Cart</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>{name === "" ? "Login" : name}</p>
      </MenuItem>
    </Menu>
  );

  let customerdetail = localStorage.getItem("customerdetail");

  React.useEffect(() => {
    getCartData();
    if (customerdetail !== null) {
      getWishlistData();
    } else {
      setWishlistCount(0);
    }
    if (customerdetail !== null) {
      setName(JSON.parse(localStorage.getItem("customerdetail"))[0].name);
    } else {
      setName("");
    }
  }, [open, customerdetail]);

  const getCartData = async () => {
    onSnapshot(collection(db, "website_cart"), (doc) => {
      const newData = doc.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      if (customerdetail !== null) {
        let a = newData.filter(
          (el) =>
            el.userId ===
            JSON.parse(localStorage.getItem("customerdetail"))[0].id
        );
        setCartCount(a.length);
      } else {
        let a = newData.filter((el) => el.userId === new DeviceUUID().get());
        setCartCount(a.length);
      }
    });
  };
  const getWishlistData = async () => {
    onSnapshot(collection(db, "website_wishlist"), (doc) => {
      const newData = doc.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      let a = newData.filter(
        (el) =>
          el.userId === JSON.parse(localStorage.getItem("customerdetail"))[0].id
      );

      setWishlistCount(a.length);
    });
  };

  const handleSearch = async (text) => {
    setSearchText(text);
    if (searchText !== "") {
      const q = query(
        collection(db, "dashboard_product"),
        where("qaChecked", "==", true)
      );

      await getDocs(q).then((querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        let list = newData.filter((e) =>
          e.productName.toLowerCase().includes(text)
        );
        setSearchProduct(list);
      });
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ backgroundColor: BackgroundColor }}>
        <Toolbar>
          <Link href="/" underline="none">
            <Avatar
              src={Logo}
              alt="logo"
              variant="square"
              sx={{ width: 136, height: 65 }}
            />
            {/* <Typography sx={{ color: "#FFF", fontSize: 30 }}>
              Your Logo
            </Typography> */}
          </Link>
          <IconButton
            size="large"
            aria-label="show 4 new mails"
            color={TextColorBlack}
            onClick={()=> navigate('/blogcategorylist')}
          >
            <Typography sx={{ marginRight: 1, color: TextColorBlack }}>
              Blogs
            </Typography>
          </IconButton>

          <IconButton
            size="large"
            aria-label="show 4 new mails"
            color={TextColorBlack}
            onClick={()=> navigate('/services')}
          >
            <Typography sx={{ marginRight: 1, color: TextColorBlack }}>
              Services
            </Typography>
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />

          <Search sx={{ display: { xs: "none", md: "block" } }}>
            <SearchIconWrapper>
              <SearchIcon sx={{ color: TextColorBlack }} />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search for products, brands and more"
              inputProps={{
                "aria-label": "Search for products, brands and more",
              }}
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
              sx={{ color: TextColorBlack }}
            />
          </Search>
          {searchText !== "" ? (
            <Grid
              container
              sx={{
                p: 2,
                position: "fixed",
                display: "flex",
                justifyContent: "center",
                top: { md: 50, xs: 78 },
                right: { xs: 2, md: 0 },
              }}
            >
              <Grid item xs={12} md={8}>
                <Paper
                  sx={{
                    p: 2,
                    maxHeight: "60vh",
                    overflowY: "auto",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: 1,
                      borderBottomColor: "#DDE2E1",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "600",
                      }}
                    >
                      Search result ({searchProduct.length})
                    </Typography>
                    <IconButton onClick={() => setSearchText("")}>
                      <Close color="info" />
                    </IconButton>
                  </Box>
                  {searchProduct?.map((item, index) => (
                    <Box
                      sx={{
                        marginTop: 3,
                        borderBottom: 1,
                        borderBottomColor: "#DDE2E1",
                        p: 2,
                      }}
                      key={index}
                    >
                      <Grid container>
                        <Grid item xs={12} md={2} sx={{ marginRight: 3 }}>
                          <Link href={`/productdetail/${item.id}`}>
                            <Avatar
                              src={
                                item.variants !== undefined
                                  ? item.variants[0]?.thumb
                                  : null
                              }
                              alt=""
                              variant="square"
                              sx={{ width: 106, height: 136 }}
                            />
                          </Link>
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Link
                              href={`/productdetail/${item.id}`}
                              sx={{ color: "blue", textDecoration: "none" }}
                            >
                              <Typography>{item.productName}</Typography>
                            </Link>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                marginRight: 2,
                                backgroundColor: "green",
                                color: "white",
                                paddingLeft: 1,
                                paddingRight: 1,
                                borderRadius: 1,
                              }}
                            >
                              3.7 ★
                            </Typography>
                            <Typography sx={{ marginRight: 2 }}>
                              (3167)
                            </Typography>
                            <Avatar
                              src="https://static-assets-web.flixcart.com/fk-p-linchpin-web/fk-cp-zion/img/fa_62673a.png"
                              alt=""
                              variant="square"
                              sx={{ width: 56, height: 15 }}
                            />
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: 2,
                            }}
                          >
                            <Typography
                              sx={{
                                marginRight: 2,
                                fontSize: 20,
                                fontWeight: "600",
                              }}
                            >
                              ₹{item.productSalePrice}
                            </Typography>
                            <Typography
                              sx={{
                                marginRight: 2,
                                textDecoration: "line-through",
                              }}
                            >
                              ₹{item.productBasePrice}
                            </Typography>
                            <Typography sx={{ color: "green" }}>
                              {100 -
                                (
                                  (parseInt(item.productSalePrice) /
                                    parseInt(item.productBasePrice)) *
                                  100
                                ).toFixed(0)}
                              % off
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                </Paper>
              </Grid>
            </Grid>
          ) : null}

          <Box
            sx={{ display: { xs: "none", md: "flex", alignItems: "center" } }}
          >
            {/* <IconButton
              size="large"
              aria-label="show 4 new mails"
              color={TextColorBlack}
            >
              <Link
                href="https://flipspoof-dashboard.vercel.app/signup"
                sx={{ color: TextColorBlack, textDecoration: "none" }}
                target="_blank"
              >
                <Typography sx={{ marginRight: 1 }}>Become seller</Typography>
              </Link>

              <Storefront />
            </IconButton> */}
            <IconButton
              size="large"
              aria-label="show 4 new mails"
              color={TextColorBlack}
              onClick={() => handleWishlist()}
            >
              <Typography sx={{ marginRight: 1, color: TextColorBlack }}>
                Wishlist
              </Typography>

              <Badge badgeContent={wishlistCount} color="error">
                <Favorite />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 4 new mails"
              color={TextColorBlack}
              onClick={() => handleCart()}
            >
              <Typography sx={{ marginRight: 1, color: TextColorBlack }}>
                Cart
              </Typography>
              <Badge badgeContent={cartCount} color="error">
                <ShoppingCart />
              </Badge>
            </IconButton>

            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color={TextColorBlack}
            >
              <Typography sx={{ marginRight: 1, color: TextColorBlack }}>
                {name === "" ? "Login" : name}
              </Typography>
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
        <Search sx={{ display: { md: "none" } }}>
          <SearchIconWrapper>
            <SearchIcon sx={{ color: TextColorBlack }} />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search for products, brands and more"
            inputProps={{
              "aria-label": "Search for products, brands and more",
            }}
            sx={{ color: TextColorBlack }}
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Search>
      </AppBar>

      <LoginDialog open={open} setOpen={setOpen} showcancel={true} />
      {renderMobileMenu}
      {renderMenu}
      <Box sx={{ marginTop: { xs: 12, md: 8 } }}>
        <CategorySlider stylee="circular" width={56} height={56} />
      </Box>
    </Box>
  );
}
