import React from "react";
import MainHeader from "../../components/mainHeader/MainHeader";
import Footer from "../../components/footer/Footer";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { DeviceUUID } from "device-uuid";
import { db } from "../../firebase";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import { Add, Delete, Remove, SafetyCheck } from "@mui/icons-material";
import LoginDialog from "../../components/loginDialog/LoginDialog";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function Cart() {
  const navigate = useNavigate();
  let customerdetail = localStorage.getItem("customerdetail");
  const [open, setOpen] = React.useState(false);
  const [cart, setCart] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [couponAmnt, setCouponAmnt] = React.useState(0);
  const [amntType, setAmntType] = React.useState("");
  const [couponIndex, setCouponIndex] = React.useState(0);

  React.useEffect(() => {
    getCartData();
  }, [customerdetail]);

  const getCartData = async () => {
    let customerdetail = localStorage.getItem("customerdetail");

    onSnapshot(
      collection(db, "website_cart"),

      async (docc) => {
        const newData = docc.docs.map((doccc) => ({
          ...doccc.data(),
          id: doccc.id,
        }));

        if (customerdetail !== null) {
          const newdata = await Promise.all(
            newData
              .filter(
                (el) =>
                  el.userId ===
                  JSON.parse(localStorage.getItem("customerdetail"))[0].id
              )
              ?.map(async (el) => {
                const snap = await getDoc(
                  doc(db, "dashboard_product", el.productId)
                );

                return Object.assign({}, snap.data(), {
                  cartid: el.id,
                  coupons: el.couponsAssociated,
                  id: snap.id,
                  thumb: el.cartItem[0].variants[0].thumb,
                  size: el.cartItem[0].variants[0].size,
                  color: el.cartItem[0].variants[0].color,
                  qty: el.cartItem[0].qty,
                  code: "0",
                  codevalue: 0,
                  codetype: "amntt",
                });
              })
          );

          setCart(newdata.flat());
        } else {
          const newdata = await Promise.all(
            newData
              .filter((el) => el.userId === new DeviceUUID().get())
              ?.map(async (el) => {
                const snap = await getDoc(
                  doc(db, "dashboard_product", el.productId)
                );

                return Object.assign({}, snap.data(), {
                  cartid: el.id,
                  id: snap.id,
                  thumb: el.cartItem[0].variants[0].thumb,
                  size: el.cartItem[0].variants[0].size,
                  color: el.cartItem[0].variants[0].color,
                  qty: el.cartItem[0].qty,
                  code: "0",
                  codevalue: 0,
                  codetype: "amntt",
                });
              })
          );

          setCart(newdata.flat());
        }
      }
    );
  };

  const handleRemove = async (id) => {
    setLoading(true);
    const docRef = doc(db, "website_cart", id);

    deleteDoc(docRef)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handlePlusQty = async (id, color, size, thumb, qty) => {
    setLoading(true);
    await updateDoc(doc(db, "website_cart", id), {
      cartItem: [
        {
          qty: qty + 1,
          variants: [{ color: color, size: size, thumb: thumb }],
        },
      ],
    });
    setLoading(false);
  };

  const handleMinusQty = async (id, color, size, thumb, qty) => {
    setLoading(true);
    await updateDoc(doc(db, "website_cart", id), {
      cartItem: [
        {
          qty: qty - 1,
          variants: [{ color: color, size: size, thumb: thumb }],
        },
      ],
    });
    setLoading(false);
  };

  const totalPrice = cart.reduce((price, item) => {
    return (
      price +
      item?.productSalePrice * item?.qty -
      (item.codetype === "percentage"
        ? (item.productSalePrice * item.qty * (item.codevalue / 100)).toFixed(0)
        : item.codetype === "amnt"
        ? item.codevalue
        : 0)
    );
  }, 0);

  const totalDiscount = cart.reduce((price, item) => {
    return (
      price +
      item?.productBasePrice * item?.qty -
      (item.codetype === "percentage"
        ? (item.productBasePrice * item.qty * (item.codevalue / 100)).toFixed(0)
        : item.codetype === "amnt"
        ? item.codevalue
        : 0)
    );
  }, 0);

  const handlePlaceOrder = () => {
    let customerdetail = localStorage.getItem("customerdetail");
    if (customerdetail !== null) {
      navigate("/checkout", {
        state: {
          cart: cart,
          totalDiscount: totalDiscount,
          totalPrice: totalPrice < 500 ? totalPrice + 40 : totalPrice,
        },
      });
    } else {
      setOpen(true);
    }
  };

  const handleChangeCouponAmnt = (event) => {
    setCouponAmnt(parseInt(event.target.value));
  };

  const handleCouponIndex = (index, code, type, value) => {
    setAmntType(type);

    const list = [...cart];

    Object.assign({}, cart, {
      code: (list[index]["code"] = code),
      codevalue: (list[index]["codevalue"] = value),
      codetype: (list[index]["codetype"] = type),
    });
  };

  return (
    <>
      <MainHeader />
      <LoginDialog open={open} setOpen={setOpen} showcancel={true} />

      {cart.length !== 0 ? (
        <Grid container gap={2} sx={{ marginTop: 3, marginBottom: 3, p: 2 }}>
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 2, maxHeight: "60vh", overflowY: "auto" }}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "600",
                  borderBottom: 1,
                  borderBottomColor: "#DDE2E1",
                }}
              >
                My cart ({cart.length})
              </Typography>
              {cart?.map((item, index) => (
                <Box
                  sx={{
                    marginTop: 3,
                    borderBottom: 1,
                    borderBottomColor: "#DDE2E1",
                    p: 2,
                  }}
                  key={index}
                >
                  <Grid container>
                    <Grid item xs={12} md={2} sx={{ marginRight: 3 }}>
                      <Link href={`/productdetail/${item.id}`}>
                        <Avatar
                          src={item.thumb}
                          alt=""
                          variant="square"
                          sx={{ width: 106, height: 136 }}
                        />
                      </Link>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          onClick={() =>
                            item.qty > 1
                              ? handleMinusQty(
                                  item.cartid,
                                  item.color,
                                  item.size,
                                  item.thumb,
                                  item.qty
                                )
                              : handleRemove(item.cartid)
                          }
                          disabled={loading === true ? true : false}
                        >
                          <Remove />
                        </IconButton>
                        <Typography>{item.qty}</Typography>
                        <IconButton
                          onClick={() =>
                            handlePlusQty(
                              item.cartid,
                              item.color,
                              item.size,
                              item.thumb,
                              item.qty
                            )
                          }
                          disabled={loading === true ? true : false}
                        >
                          <Add />
                        </IconButton>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Link
                          href={`/productdetail/${item.id}`}
                          sx={{ color: "blue", textDecoration: "none" }}
                        >
                          <Typography>{item.productName}</Typography>
                        </Link>

                        <IconButton onClick={() => handleRemove(item.cartid)}>
                          <Delete color="error" />
                        </IconButton>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            marginRight: 2,
                            backgroundColor: "green",
                            color: "white",
                            paddingLeft: 1,
                            paddingRight: 1,
                            borderRadius: 1,
                          }}
                        >
                          3.7 ★
                        </Typography>
                        <Typography sx={{ marginRight: 2 }}>(3167)</Typography>
                        <Avatar
                          src="https://static-assets-web.flixcart.com/fk-p-linchpin-web/fk-cp-zion/img/fa_62673a.png"
                          alt=""
                          variant="square"
                          sx={{ width: 56, height: 15 }}
                        />
                      </Box>
                      <Typography>Size: {item.size}</Typography>
                      <Typography>Color: {item.color}</Typography>
                      <Typography>Qty: {item.qty}</Typography>

                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              sx={{
                                marginRight: 2,
                                fontSize: 20,
                                fontWeight: "600",
                              }}
                            >
                              ₹
                              {item.codetype === "percentage"
                                ? (
                                    item.productSalePrice * item.qty -
                                    item.productSalePrice *
                                      item.qty *
                                      (item.codevalue / 100)
                                  ).toFixed(0)
                                : item.productSalePrice * item.qty -
                                  item.codevalue}
                            </Typography>
                            <Typography
                              sx={{
                                marginRight: 2,
                                textDecoration: "line-through",
                              }}
                            >
                              ₹{item.productBasePrice * item.qty}
                            </Typography>
                            <Typography sx={{ color: "green" }}>
                              {100 -
                                (
                                  (parseInt(item.productSalePrice) /
                                    parseInt(item.productBasePrice)) *
                                  100
                                ).toFixed(0)}
                              % off
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          {item?.coupons?.length > 0 ? (
                            <Box
                              sx={{
                                minWidth: 150,
                                marginTop: { xs: 2, md: 0 },
                              }}
                            >
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Coupons
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={
                                    item.codevalue === 0 ? 0 : item.codevalue
                                  }
                                  label="Coupons"
                                  onChange={handleChangeCouponAmnt}
                                  size="small"
                                >
                                  <MenuItem
                                    value={0}
                                    onClick={() =>
                                      handleCouponIndex(index, "0", "amnt", 0)
                                    }
                                  >
                                    <em>Choose</em>
                                  </MenuItem>
                                  {item.coupons?.map((e, idx) => (
                                    <MenuItem
                                      value={parseInt(e.couponTypevalue)}
                                      key={idx}
                                      onClick={() =>
                                        handleCouponIndex(
                                          index,
                                          e.code,
                                          e.coupounType,
                                          parseInt(e.couponTypevalue)
                                        )
                                      }
                                    >
                                      {e.code} ({e.couponTypevalue}{" "}
                                      {e.coupounType === "percentage"
                                        ? "%"
                                        : "Rs"}{" "}
                                      {"Off"})
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              ))}
              {cart.length === 0 ? (
                <Grid container sx={{ marginTop: 20, marginBottom: 20 }}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 3,
                    }}
                  >
                    <Typography variant="h5">No any item in cart</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button variant="contained">Continue shopping</Button>
                  </Grid>
                </Grid>
              ) : null}
            </Paper>
          </Grid>
          <Grid item xs={12} md={3}>
            <Paper sx={{ p: 2 }}>
              <Typography
                variant="h6"
                sx={{ borderBottom: 1, borderBottomColor: "#DDE2E1" }}
              >
                PRICE DETAILS
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 2,
                }}
              >
                <Typography>Prices: ({cart.length} item)</Typography>
                <Typography>₹{totalPrice}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 2,
                }}
              >
                <Typography>Discount:</Typography>
                <Typography sx={{ color: "green" }}>
                  -₹{totalDiscount}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 2,
                }}
              >
                <Typography>Delivery Charges:</Typography>
                <Typography sx={{ color: "green" }}>
                  {totalPrice > 500 ? "Free" : "₹40"}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 2,
                  borderTop: "dotted",
                  borderBottom: "dotted",
                  borderWidth: 2,
                  paddingTop: 2,
                  paddingBottom: 2,
                  borderColor: "#DDE2E1",
                }}
              >
                <Typography variant="h5" sx={{ fontSize: 22 }}>
                  Total Amount:
                </Typography>
                <Typography variant="h5" sx={{ fontSize: 22 }}>
                  ₹{totalPrice > 500 ? totalPrice : totalPrice + 40}
                </Typography>
              </Box>
            </Paper>
            <Paper
              elevation={0}
              sx={{ marginTop: 1, display: "flex", justifyContent: "flex-end" }}
            >
              <Button variant="contained" onClick={handlePlaceOrder}>
                Place order
              </Button>
            </Paper>
            <Paper sx={{ marginTop: 3 }} elevation={0}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <SafetyCheck />
                <Typography sx={{ marginLeft: 1 }}>
                  Safe and Secure Payments.Easy returns.100% Authentic products.
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", m: 20 }}>
          <Typography variant="h5">No any product in cart</Typography>
        </Box>
      )}
      <Footer />
    </>
  );
}
