import React from "react";
import MainHeader from "../../components/mainHeader/MainHeader";
import Footer from "../../components/footer/Footer";
import {
  Avatar,
  Grid,
  Box,
  Paper,
  Typography,
  Button,
  IconButton,
  Link,
  Dialog,
  AppBar,
  Toolbar,
} from "@mui/material";
import { ArrowForwardIos, Close, FiberManualRecord } from "@mui/icons-material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import ProductRating from "../../components/rating/ProductRating";

export default function Orders() {
  let customerdetail = localStorage.getItem("customerdetail");
  const [searchtext, setSearchText] = React.useState("");
  const navigate = useNavigate();
  const [orderList, setOrderList] = React.useState([]);
  const [filteredList, setFilteredOrderList] = React.useState([]);
  const [openRateDialog, setOpenRateDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [productDelivered, setProductDelivered] = React.useState(false);
  const [alreadyRated, setAlreadyRated] = React.useState(false);
  const [submit, setSubmit] = React.useState(false);

  const handleOrderDetail = (item) => {
    navigate("/orderdetail", { state: { data: item } });
  };

  React.useEffect(() => {
    getOrders();
  }, []);

  const getOrders = async () => {
    const q = query(
      collection(db, "dashboard_orders"),
      where("customerId", "==", JSON.parse(customerdetail)[0].id)
    );

    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setOrderList(newData);
    });
  };

  const handleSearch = (text) => {
    if (text === "All") {
      setSearchText(text);

      setFilteredOrderList([]);
    } else {
      setSearchText(text);
      const filteredArray = orderList.map((mainObj) => ({
        ...mainObj,
        orderItems: mainObj.orderItems.filter(
          (subObj) => subObj.itemStatus === text
        ),
      }));
      setFilteredOrderList(filteredArray.flat());
    }
  };

  const getProductData = async (id) => {
    const snap = await getDoc(doc(db, "dashboard_product", id));
    if (snap.exists()) {
      let customerdetail = localStorage.getItem("customerdetail");

      if (customerdetail !== null) {
        let aa = snap
          .data()
          ?.orderedBy.find(
            (e) => e.customerId === JSON.parse(customerdetail)[0].id
          );

        if (aa !== undefined) {
          if (aa.status === "Delivered") {
            setProductDelivered(true);
          } else {
            setProductDelivered(false);
          }
        } else {
          setProductDelivered(false);
        }
      }

      if (customerdetail !== null) {
        let aa = snap
          .data()
          ?.productRating.find(
            (e) => e.customerId === JSON.parse(customerdetail)[0].id
          );

        if (aa !== undefined) {
          if (aa.ratingCount > 0) {
            setAlreadyRated(true);
          } else {
            setAlreadyRated(false);
          }
        } else {
          setAlreadyRated(false);
        }
      }
    } else {
      console.log("No such document");
    }
  };

  const handleRateProduct = (id) => {
    let customerdetail = localStorage.getItem("customerdetail");
    getProductData(id);

    if (customerdetail !== null) {
      setOpenRateDialog(true);
    } else {
      setOpen(true);
    }
  };

  const handleCloserateDialog = (id) => {
    setOpenRateDialog(false);
    setSubmit(false);
  };

  return (
    <>
      <MainHeader />
      <Typography variant="h5" sx={{ p: 3 }}>
        My orders
      </Typography>
      <Grid container>
        <Grid item xs={12} md={3} sx={{ p: 3 }}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6">Filters</Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value="All"
                    onClick={(e) => handleSearch(e.target.value)}
                    checked={searchtext === "All" ? true : false}
                  />
                }
                label="All"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="Delivered"
                    onClick={(e) => handleSearch(e.target.value)}
                    checked={searchtext === "Delivered" ? true : false}
                  />
                }
                label="Delivered"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="Cancelled"
                    onClick={(e) => handleSearch(e.target.value)}
                    checked={searchtext === "Cancelled" ? true : false}
                  />
                }
                label="Cancelled"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="Pending"
                    onClick={(e) => handleSearch(e.target.value)}
                    checked={searchtext === "Pending" ? true : false}
                  />
                }
                label="Pending"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="Return"
                    onClick={(e) => handleSearch(e.target.value)}
                    checked={searchtext === "Return" ? true : false}
                  />
                }
                label="Return"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="Replace"
                    onClick={(e) => handleSearch(e.target.value)}
                    checked={searchtext === "Replace" ? true : false}
                  />
                }
                label="Replace"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="Return cancel"
                    onClick={(e) => handleSearch(e.target.value)}
                    checked={searchtext === "Return cancel" ? true : false}
                  />
                }
                label="Return cancelled"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    value="Shipped"
                    onClick={(e) => handleSearch(e.target.value)}
                    checked={searchtext === "Shipped" ? true : false}
                  />
                }
                label="Shipped"
              />
            </FormGroup>
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          {filteredList.length > 0
            ? filteredList.map((item) =>
                item.orderItems.map((e, index) => (
                  <Box sx={{ p: 3 }} key={index}>
                    <Paper sx={{ p: 3 }}>
                      <Grid container>
                        <Grid item xs={12} md={2}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Avatar
                              src={e.thumb}
                              alt=""
                              variant="square"
                              sx={{ width: 76, height: 76 }}
                            />
                            <IconButton
                              onClick={() => handleOrderDetail(item)}
                              sx={{ display: { xs: "block", md: "none" } }}
                            >
                              <ArrowForwardIos
                                color="primary"
                                sx={{ width: 30, height: 30 }}
                              />
                            </IconButton>
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Link
                            href={`/productdetail/${e.productId}`}
                            sx={{ textDecoration: "none" }}
                          >
                            <Typography
                              variant="h6"
                              sx={{ fontSize: 15, textDecoration: "none" }}
                            >
                              {e.productName}
                            </Typography>
                          </Link>

                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography sx={{ fontSize: 13 }}>
                              Color: {e.variants[0].variantValue}
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              Size: {e.variants[1].variantValue}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Typography>
                            ₹
                            {e.codetype === "percentage"
                              ? (
                                  e.salePrice * e.qty -
                                  e.salePrice * e.qty * (e.codevalue / 100)
                                ).toFixed(0)
                              : e.salePrice * e.qty - e.codevalue}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Box sx={{ display: "flex" }}>
                            <FiberManualRecord
                              color={
                                e.itemStatus === "Pending"
                                  ? "info"
                                  : e.itemStatus === "Delivered"
                                  ? "success"
                                  : e.itemStatus === "Shipped"
                                  ? "secondary"
                                  : e.itemStatus === "Return cancel"
                                  ? "success"
                                  : e.itemStatus !== "Cancelled"
                                  ? "warning"
                                  : "error"
                              }
                            />
                            <Typography>
                              {e.itemStatus === "Pending"
                                ? "Expected delivery "
                                : e.itemStatus === "Delivered"
                                ? "Delivered "
                                : e.itemStatus !== "Cancelled"
                                ? "Expected delivery "
                                : "Cancelled "}
                              on{" "}
                              {e.itemStatus === "Pending"
                                ? e.deliveryDate.slice(0, 16)
                                : e.itemStatus === "Delivered"
                                ? e.deliveryDate.slice(0, 16)
                                : e.itemStatus !== "Cancelled"
                                ? e.deliveryDate.slice(0, 16)
                                : e.cancelDate.slice(0, 16)}
                            </Typography>
                          </Box>
                          <Button
                            variant="text"
                            sx={{ fontSize: 15, textTransform: "capitalize" }}
                            onClick={() => handleRateProduct(e.productId)}
                          >
                            ★ Rate and review product
                          </Button>
                          <Dialog
                            open={openRateDialog}
                            onClose={() => setOpenRateDialog(false)}
                            maxWidth="xl"
                          >
                            <AppBar sx={{ position: "relative" }}>
                              <Toolbar
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <IconButton
                                  edge="start"
                                  color="inherit"
                                  onClick={() =>
                                    handleCloserateDialog(e.productId)
                                  }
                                  aria-label="close"
                                >
                                  <Close />
                                </IconButton>
                                <Button
                                  variant="contained"
                                  sx={{ backgroundColor: "#FF9F00" }}
                                  onClick={() => setSubmit(true)}
                                  disabled={
                                    submit === true ||
                                    alreadyRated === true ||
                                    productDelivered === false
                                      ? true
                                      : false
                                  }
                                >
                                  Submit
                                </Button>
                              </Toolbar>
                            </AppBar>
                            {productDelivered === true ? (
                              alreadyRated === false ? (
                                <ProductRating
                                  id={item.productId}
                                  submit={submit}
                                />
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    p: 5,
                                  }}
                                >
                                  <Typography variant="h5">
                                    Already rated
                                  </Typography>
                                </Box>
                              )
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  p: 5,
                                }}
                              >
                                <Typography variant="h5">
                                  Product not yet purchased
                                </Typography>
                              </Box>
                            )}
                          </Dialog>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={1}
                          sx={{ display: { xs: "none", md: "block" } }}
                        >
                          <IconButton onClick={() => handleOrderDetail(item)}>
                            <ArrowForwardIos />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Box>
                ))
              )
            : orderList.map((item) =>
                item.orderItems.map((e, index) => (
                  <Box sx={{ p: 3 }} key={index}>
                    <Paper sx={{ p: 3 }}>
                      <Grid container>
                        <Grid item xs={12} md={2}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Avatar
                              src={e.thumb}
                              alt=""
                              variant="square"
                              sx={{ width: 76, height: 76 }}
                            />
                            <IconButton
                              onClick={() => handleOrderDetail(item)}
                              sx={{ display: { xs: "block", md: "none" } }}
                            >
                              <ArrowForwardIos
                                color="primary"
                                sx={{ width: 30, height: 30 }}
                              />
                            </IconButton>
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Link
                            href={`/productdetail/${e.productId}`}
                            sx={{ textDecoration: "none" }}
                          >
                            <Typography
                              variant="h6"
                              sx={{ fontSize: 15, textDecoration: "none" }}
                            >
                              {e.productName}
                            </Typography>
                          </Link>

                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography sx={{ fontSize: 13 }}>
                              Color: {e.variants[0].variantValue}
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              Size: {e.variants[1].variantValue}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Typography>
                            ₹
                            {e.codetype === "percentage"
                              ? (
                                  e.salePrice * e.qty -
                                  e.salePrice * e.qty * (e.codevalue / 100)
                                ).toFixed(0)
                              : e.salePrice * e.qty - e.codevalue}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Box sx={{ display: "flex" }}>
                            <FiberManualRecord
                              color={
                                e.itemStatus === "Pending"
                                  ? "info"
                                  : e.itemStatus === "Delivered"
                                  ? "success"
                                  : e.itemStatus === "Shipped"
                                  ? "secondary"
                                  : e.itemStatus === "Return cancel"
                                  ? "success"
                                  : e.itemStatus !== "Cancelled"
                                  ? "warning"
                                  : "error"
                              }
                            />
                            <Typography>
                              {e.itemStatus === "Pending"
                                ? "Expected delivery "
                                : e.itemStatus === "Delivered"
                                ? "Delivered "
                                : e.itemStatus !== "Cancelled"
                                ? "Expected delivery "
                                : "Cancelled "}
                              on{" "}
                              {e.itemStatus === "Pending"
                                ? e.deliveryDate.slice(0, 16)
                                : e.itemStatus === "Delivered"
                                ? e.deliveryDate.slice(0, 16)
                                : e.itemStatus !== "Cancelled"
                                ? e.deliveryDate.slice(0, 16)
                                : e.cancelDate.slice(0, 16)}
                            </Typography>
                          </Box>
                          <Button
                            variant="text"
                            sx={{ fontSize: 15, textTransform: "capitalize" }}
                            onClick={() => handleRateProduct(e.productId)}
                          >
                            ★ Rate and review product
                          </Button>
                          <Dialog
                            open={openRateDialog}
                            onClose={() => setOpenRateDialog(false)}
                            maxWidth="xl"
                          >
                            <AppBar sx={{ position: "relative" }}>
                              <Toolbar
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <IconButton
                                  edge="start"
                                  color="inherit"
                                  onClick={() =>
                                    handleCloserateDialog(e.productId)
                                  }
                                  aria-label="close"
                                >
                                  <Close />
                                </IconButton>
                                <Button
                                  variant="contained"
                                  sx={{ backgroundColor: "#FF9F00" }}
                                  onClick={() => setSubmit(true)}
                                  disabled={
                                    submit === true ||
                                    alreadyRated === true ||
                                    productDelivered === false
                                      ? true
                                      : false
                                  }
                                >
                                  Submit
                                </Button>
                              </Toolbar>
                            </AppBar>
                            {productDelivered === true ? (
                              alreadyRated === false ? (
                                <ProductRating
                                  id={item.productId}
                                  submit={submit}
                                />
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    p: 5,
                                  }}
                                >
                                  <Typography variant="h5">
                                    Already rated
                                  </Typography>
                                </Box>
                              )
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  p: 5,
                                }}
                              >
                                <Typography variant="h5">
                                  Product not yet purchased
                                </Typography>
                              </Box>
                            )}
                          </Dialog>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={1}
                          sx={{ display: { xs: "none", md: "block" } }}
                        >
                          <IconButton onClick={() => handleOrderDetail(item)}>
                            <ArrowForwardIos />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Box>
                ))
              )}
        </Grid>
      </Grid>

      <Footer />
    </>
  );
}
