import React from "react";
import MainHeader from "../../components/mainHeader/MainHeader";
import Footer from "../../components/footer/Footer";

import { Avatar, Box, Grid, Typography } from "@mui/material";

import { useLocation } from "react-router-dom";

export default function BlogDetail() {
  const location = useLocation();

  return (
    <>
      <MainHeader />
      <Box
        sx={{
          padding: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            color: "#000",
            fontSize: 30,
            fontWeight: "600",
            lineHeight: 1,
            textAlign: "center",
          }}
        >
          {location.state.data.blogHeading}
        </Typography>
        <Avatar
          alt=""
          src={location.state.data.featuredImage}
          variant="square"
          sx={{ width: "60%", height: 300, marginTop: 2 }}
        />
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: "500",
            marginTop: 2,
            marginBottom: 5,
          }}
        >
          {location.state.data.blogShortDesc}
        </Typography>
        {location.state.data.blogLongDesc.map((item, index) => (
          <Grid
            container
            key={index}
            sx={{
              display: "flex",
              flexDirection: index % 2 === 0 ? "row" : "row-reverse",
              alignItems: "center",
              marginBottom: 5,
            }}
          >
            <Grid item xs={12} md={6}>
              <Typography
                sx={{ fontWeight: "600", color: "#000", fontSize: 25 }}
              >
                {index + 1}. {item.title}
              </Typography>
              <Typography>{item.desc}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar
                alt=""
                src={item.descthumb}
                variant="square"
                sx={{ width: 300, height: 300 }}
              />
            </Grid>
          </Grid>
        ))}
      </Box>
      <Footer />
    </>
  );
}
