import React from "react";
import MainHeader from "../../components/mainHeader/MainHeader";
import Footer from "../../components/footer/Footer";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../../firebase";
import { Box, Grid, Typography } from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 200,
  [theme.breakpoints.down("sm")]: {
    width: "94% !important", // Overrides inline-style
    height: 100,
  },
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.15,
    },
    "& .MuiImageMarked-root": {
      opacity: 0,
    },
    "& .MuiTypography-root": {
      border: "4px solid currentColor",
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
});
const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create("opacity"),
}));

const ImageMarked = styled("span")(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: "absolute",
  bottom: -2,
  left: "calc(50% - 9px)",
  transition: theme.transitions.create("opacity"),
}));

export default function BlogCategoryList() {
  let navigate = useNavigate();
  const [blogCategoryData, setBlogCategoryData] = React.useState([]);

  React.useEffect(() => {
    getBlogsCategory();
  }, []);

  const getBlogsCategory = async () => {
    const q = query(collection(db, "blogs_category"));
    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setBlogCategoryData(newData);
      console.log({ newData });
    });
  };

  return (
    <>
      <MainHeader />
      <Typography
        sx={{
          color: "#102D5E",
          fontSize: 40,
          fontWeight: "600",
          textAlign: "center",

          marginTop: 5,
        }}
      >
        Blogs
      </Typography>
      <Box sx={{ padding: 5 }}>
        <Grid container>
          {blogCategoryData.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <ImageButton
                focusRipple
                style={{
                  width: 300,
                  height: 300,
                  margin: 10,
                }}
                onClick={() =>
                  navigate("/blogcategory", { state: { data: item.name } })
                }
              >
                <ImageSrc style={{ backgroundImage: `url(${item.image})` }} />
                <ImageBackdrop className="MuiImageBackdrop-root" />
                <Image>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    sx={{
                      position: "relative",
                      p: 1,
                      pt: 2,
                      pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                      marginLeft: 2,
                      marginRight: 2,
                    }}
                  >
                    {item.name}
                    <ImageMarked className="MuiImageMarked-root" />
                  </Typography>
                </Image>
              </ImageButton>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Footer />
    </>
  );
}
