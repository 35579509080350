import React from "react";

import SimpleImageSlider from "react-simple-image-slider";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { Box } from "@mui/material";

export default function ImageSlider({ place }) {
  const [banners, setBanners] = React.useState([]);
  const [mobileBanners, setMobileBanners] = React.useState([]);

  React.useEffect(() => {
    getBanners();
  }, []);

  const getBanners = async () => {
    await getDocs(collection(db, "website_banners")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setBanners(
        newData.filter((e) => e.place === place && e.type === "desktop")
      );
      setMobileBanners(newData.filter((e) => e.type === "mobile"));
    });
  };


  return banners.length > 0 ? (
    <>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <SimpleImageSlider
          width="100%"
          height="280px"
          images={banners.map((e) => e.image)}
          showNavs={true}
          autoPlay={true}
          showBullets={true}
          autoPlayDelay={3}
        />
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <SimpleImageSlider
          width="100%"
          height="280px"
          images={mobileBanners.map((e) => e.image)}
          showNavs={true}
          autoPlay={true}
          showBullets={true}
          autoPlayDelay={3}
        />
      </Box>
    </>
  ) : null;
}
