import * as React from "react";

import Box from "@mui/material/Box";
import { Paper } from "@mui/material";

import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import Button from "@mui/material/Button";

import { Add, Remove } from "@mui/icons-material";

import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import CircularProgress from "@mui/material/CircularProgress";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";

export default function ProductRating({ id, submit }) {
  let customerdetail = JSON.parse(localStorage.getItem("customerdetail"));
  const [indexThumb, setIndexThumb] = React.useState(0);
  const [loading2, setLoading2] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const [ratings, setRatings] = React.useState([
    {
      ratingText: "",
      ratingCount: 4,
      customerId: customerdetail[0].id,
      gallery: [{ link: "" }],
    },
  ]);
  const [rated, setRated] = React.useState([]);

  const handleChangeGallery = async (e) => {
    setLoading2(true);
    if (e.target.files[0]) {
      const storage = getStorage();

      const mountainImagesRef = ref(
        storage,
        "dashboard_product_rating_images_customer_rating_/" +
          e.target.files[0]?.name
      );

      await uploadBytes(mountainImagesRef, e.target.files[0]).then(
        async (snapshot) => {
          await getDownloadURL(
            ref(
              storage,
              "dashboard_product_rating_images_customer_rating_/" +
                e.target.files[0]?.name
            )
          ).then((url) => {
            const list = [...ratings];

            let data = {
              link: url,
            };

            list[indexThumb].gallery.push(data);

            setRatings(list);
          });
        }
      );
    }
    setLoading2(false);
  };

  const handleChangeValue = (e, idx) => {
    const { name, value } = e.target;

    const list = [...ratings];

    list[idx][name] = value;

    setRatings(list);
  };
  const handleChangeRatingCount = (e, idx) => {
    const { name, value } = e.target;

    const list = [...ratings];

    list[idx][name] = parseInt(value);

    setRatings(list);
  };

  const removeGalleryImage = (ii, i) => {
    const list = [...ratings];

    list[ii].gallery.splice(i, 1);

    setRatings(list);
  };

  React.useEffect(() => {
    if (submit === true) {
      handleSubmit();
    }
    getProductData();
  }, [submit]);

  const getProductData = async () => {
    setLoading(true);
    const snap = await getDoc(doc(db, "dashboard_product", id));
    if (snap.exists()) {
      setRated(snap.data().productRating);
    } else {
      console.log("No such document");
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    let arr = rated.concat(ratings);
    await updateDoc(
      doc(db, "dashboard_product", id),

      {
        productRating: arr,
      }
    );
    setOpen(true);
  };
  return (
    <>
      {open === false ? (
        ratings.map((item, idx) => (
          <Box
            key={idx}
            sx={{
              display: "flex",
              flexWrap: "wrap",

              paddingBottom: 3,
              borderBottomColor: "darkgray",
              marginTop: 2,

              "& > :not(style)": {
                m: 1,
                width: "100%",
                height: 128,
              },
            }}
          >
            <Paper elevation={0}>
              <TextField
                id="ratingText"
                name="ratingText"
                fullWidth
                value={item.ratingText}
                label="Some feedback"
                variant="outlined"
                onChange={(e) => handleChangeValue(e, idx)}
                required
                disabled={loading === true ? true : false}
              />
              <Box
                sx={{
                  "& > legend": { mt: 2 },
                }}
              >
                <Typography component="legend">Rating*</Typography>
                <Rating
                  name="ratingCount"
                  id="ratingCount"
                  value={item.ratingCount}
                  onChange={(e) => handleChangeRatingCount(e, idx)}
                  disabled={loading === true ? true : false}
                />
              </Box>
            </Paper>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              {item?.gallery?.map((el, index) => (
                <Paper elevation={0} key={index}>
                  <input
                    accept="image/*"
                    name="gallery"
                    id="gallery"
                    // multiple
                    type="file"
                    onChange={(e) => handleChangeGallery(e)}
                    style={{
                      display: "none",
                      width: "106px",
                      height: "106px",
                    }}
                    disabled={loading === true ? true : false}
                  />

                  {loading2 === true && indexThumb === idx ? (
                    <Box sx={{ display: "flex" }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <label htmlFor="gallery">
                      {el.link !== "" ? (
                        <img
                          alt=""
                          src={el.link}
                          style={{
                            width: "106px",
                            height: "106px",
                          }}
                        />
                      ) : (
                        <Button
                          component="span"
                          sx={{
                            backgroundColor: "#CEF4FF",
                            width: "125px",
                            height: "125px",
                            border: "dotted",
                            borderWidth: "2px",
                            borderColor: "blue",
                          }}
                          onClick={() => setIndexThumb(idx)}
                          disabled={loading === true ? true : false}
                        >
                          <Add color="primary" />
                        </Button>
                      )}

                      {index === 0 ? null : (
                        <Button
                          startIcon={
                            <Remove
                              color="primary"
                              sx={{ backgroundColor: "#CEF4FF" }}
                            />
                          }
                          variant="text"
                          color="primary"
                          onClick={() => removeGalleryImage(idx, index)}
                          disabled={loading === true ? true : false}
                        ></Button>
                      )}
                    </label>
                  )}

                  <Typography>
                    Gallery image {index === 0 ? null : "(" + index + ")"}
                  </Typography>
                </Paper>
              ))}
            </Box>
          </Box>
        ))
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", p: 5 }}>
          <Typography variant="h5">Feedback submited</Typography>
        </Box>
      )}
    </>
  );
}
